import React, { useEffect, useState } from "react";
import Lottie from "reactjs-lottie";
//import Rule from "../assets/images/rule.svg";
import Rule from "../assets/images/rules_eye_icon_blue.png";
import ReactHtmlParser from "react-html-parser";
import Close from "../assets/images/close.svg";
import Rupee from "../assets/images/rupee.svg";
import Previous from "../assets/images/previous.svg";
import PremiumLogo from "../assets/images/premium.png";
import PremiumClose from "../assets/images/cancel_premium.png";

import {
  dateFormat,
  dateFullFormat,
  getTournamentFullDateTime,
  getTournamentDateTime,
  getProfiledateFormat,
  checkDobNstateConditions,
  checkDobNstateConditionsContestTournament
} from "./common";
import rightArrow from "../assets/images/right-arrow.svg";
import rightArrowBlack from "../assets/images/right-arrow-black.svg";
import successCheck from "../assets/images/success.json";
import pay from "../assets/images/pay.svg";
import squadDummy from "../assets/images/squad-dummy.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  getWalletLimit,
  onCreateTournament,
  getPrizePool,
  getRules,
  tournamentRoundsById,
  tournamentMatchesById,
  getUserInGameName
} from "../apis";
import { routehelp } from "../config/routehelp";
import { Link, useNavigate } from "react-router-dom";
import url from "../constants/url";
import DobNStates from "../screens/dobnstate";
import SquadRegistration from "../screens/SquadRegistration";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Logo from "../assets/images/logo.svg";

import Advertisement from "../component/advertisement";
import SquadRegistrationTournaments from "../screens/SquadRegistrationTournaments";
import { constants } from "../constants/constants";
import { useTranslation } from "react-i18next";
import roller from "../assets/images/rolling.gif";

function TournamentsStructure(props) {
  // let allFeaturedData = props?.allFeaturedData;

  // DobNState Screen Name
  const dobNstateScreenName = "tournaments-contests";

  const [showdobnstate, setShowdobnstate] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [showJoin, setShowJoin] = useState(false);
  const [showJoinTeam, setShowJoinTeam] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [coinStore, setCoinStore] = useState(false);
  const [showWinners, setShowWinners] = useState(false);
  const [coin, setCoin] = useState(0);
  const [firstRoundMatchId, setFirstRoundMatchId] = useState("");
  const [contest, setContest] = useState("");
  const [uniqueIgn, setUniqueIgn] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [rounds, setRounds] = useState([]);
  const [finaleData, setFinaleData] = useState(null);
  const [walletUsage, setWalletUsageData] = useState(null);
  const [allFeaturedData, setAllFeaturedData] = useState([]);
  const [joinTeamData, setJoinTeamData] = useState([]);
  const [allTeamsIds, setAllTeamsIds] = useState([]);
  const [numPlayers, setNumPlayers] = useState([]);
  const [playerPhone, setPlayerPhone] = useState([]);
  const [selectedPlayerData, setSelectedPlayerData] = useState([]);
  const [total_players, setTotal_players] = useState(0);
  const [extra_players, setExtra_players] = useState(0);
  const [progressWidth, setProgressWidth] = useState(0);
  const [userData, setUserData] = useState(null);
  const [currentName, setcurrentName] = useState("");
  const [showPremiumPopup, setShowPremiumPopup] = useState(false);
  const [timings, setTimings] = useState([]);
  const [isFromJoinViaCode, setIsFromJoinViaCode] = useState(false);
  const [premiumUser, setPremiumUser] = useState(
    JSON.parse(localStorage.getItem("premiumUser"))
  );
  const [clickCheck, setClickCheck] = useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation()
  const [isBusy, setIsBusy] = useState(false)
  const [prizePoolInfo, setPrizePoolInfo] = useState(null);
  const [rulesInfo, setRulesInfo] = useState(null);
  const [contestList, setContestList] = useState([]);
  const [showdobnstateInfo, setShowdobnstateInfo] = useState(null);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem(constants.profileLite)));
    var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
    // console.log("user_data", user_data);
    // console.log("props?.allFeaturedData==555>", props?.allFeaturedData);

    setIsFromJoinViaCode(props.isFromJoinViaCode);
    if (props?.allFeaturedData) {
      // console.log("props ==>", props);
      var per =
        (100 * props?.allFeaturedData?.totalJoinedPlayers) /
          props?.allFeaturedData?.totalPlayers +
        "%";
      setProgressWidth(per);
      setAllFeaturedData(props?.allFeaturedData);
      // var finale_index = props?.allFeaturedData?.allrounds?.findIndex(
      //   (val) => val?.isFinalRound?.toLowerCase() === "yes"
      // );
      // console.log("finale_index: ", finale_index);
      // console.log("allrounds: ", props?.allFeaturedData);
      // setFinaleData(
      //   finale_index !== -1
      //     ? props?.allFeaturedData?.allrounds?.[finale_index]?.matches?.[0]
      //     : []
      // );

      // setTournamnetTime();
    }
  }, [props?.allFeaturedData]);

  // navText = () => {
  //   return [
  //     <span key={'1_1'} class='lnr lnr-arrow-up'></span>,
  //     <span key={'2_2'} class='lnr lnr-arrow-down'></span>
  //   ];
  // }
  // const setTournamnetTime = () => {
  //   {
  //     props?.allFeaturedData?.contestsList?.map((item1, i) => {
  //       var tempContest = item1;
  //       // console.log("tempContest=>", tempContest);
  //       {
  //         props?.allFeaturedData?.roundDatetime?.map((item, index) => {
  //           // console.log("Date-Time: ", item);
  //           var today = new Date();
  //           var cdate = moment(item?.date).format("y-MM-DD");
  //           var ctime = moment(item?.time).format("HH:mm:ss");
  //           var contestDateTime = moment(cdate + " " + ctime);
  //           if (item.matchId == tempContest.code) {
  //             if (today < new Date(contestDateTime)) {
  //               // console.log("Date-Time: inner 1 ", item);
  //               // setTimings([...timings, ...item])
  //               timings.push(item);
  //               // return (
  //               //   <label onClick={() => onSelectTime(item)} key={index}>
  //               //     <input type="radio" name="radio" />
  //               //     <span></span>
  //               //     <p>{getTournamentFullDateTime(item?.date, item?.time)}</p>
  //               //   </label>
  //               // );
  //             }
  //           }
  //         });
  //       }
  //     });
  //   }
  // };


  const setTournamnetTime = async () => {
    let ttm = [];
    let temptime2 = [];

    //console.log("contestList",contestList)
    
    await Promise.all(
      contestList?.map(async (item1, i) => {
        var tempContest = item1;
        for (let index = 0; index < contestList.length; index++) {
          const item = contestList[index];
          var today = new Date();
          var cdate = moment(item?.date).format("y-MM-DD");
          var ctime = moment(item?.time).format("HH:mm");

          var contestDateTime = moment(cdate + " " + ctime);
          var contestdatetimestring = cdate + " " + ctime;

          if ((i=0 && item1.totalJoinedPlayers===0)) {
            temptime2.push(contestdatetimestring);
          } else {
            var diff = temptime2.includes(contestdatetimestring);
          }
          if (item.matchId === tempContest.code) {
            if (index === 0 || !diff) {
              if (today < new Date(contestDateTime)) {
                console.log("item",item)
                ttm.push(item);
                temptime2.push(contestdatetimestring);
              }
            }
          }
        }
      })
    );
    setTimeout(() => {
      setTimings(ttm);
    }, 1000);
  };

  const InGameNameApiCall = async () => {
    var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
    let payload = {
      "search": {
        "user": user_data?.item?.user_id,
        "game": localStorage.getItem('activeGameId')
      }
    }
  
    let gameNamesData = (await getUserInGameName(payload))
    return gameNamesData?.data?.[0]?.userINGameName;
  }

  const getjoin = async () => {
    let response = await tournamentRoundsById(allFeaturedData?.id);
    if (response?.success) {
   
      // let result  = response?.item.filter((item) => now <= getTournamentFullDateTime(item?.date,item?.time))
      var today = new Date();
      

      let result  = response?.item.filter((item) =>{
        
      var itDate = moment(item?.date).format("y-MM-DD");
      var itTime = moment(item?.time).format("HH:mm:ss");
     
      var itmeD = moment(itDate + " " + itTime);
      if( today < itmeD) return true
      })


      // console.log(now,result, now <= result)
      setContestList(result)
      // setContestList(response?.item)
      setFinaleData(response?.lastRound);

      setFirstRoundMatchId("");
      localStorage.setItem("joinTounament", JSON.stringify(allFeaturedData));
      // localStorage.setItem("activeGameId", allFeaturedData?.gameId);
      // localStorage.setItem("activeGame", allFeaturedData?.gameName);

      var tempRound = response?.item?.[response?.item.length - 1];

      var today = new Date();
      var cdate = moment(tempRound.date).format("y-MM-DD");
      var ctime = moment(tempRound.time).format("HH:mm:ss");
      var contestDateTime = moment(cdate + " " + ctime);

      var isShowTournament = true;
      if (result.length == 0 || today > new Date(contestDateTime)) {
        isShowTournament = false;
      }
      isShowTournament = true;
      // console.log("**********", isShowTournament)
      if (isShowTournament) {
        var uniqueI = "";
  
        localStorage.setItem("tournamentId", allFeaturedData.id);
        uniqueI = allFeaturedData?.inGameName || allFeaturedData?.userInGameName || await InGameNameApiCall();
        // console.log(uniqueI)
  
        setUniqueIgn(uniqueI);
  
        let data = {
          event: allFeaturedData.id,
          type: "event",
          currencyCode: allFeaturedData?.currency?.inCurrency?._id,
          entryFee: allFeaturedData?.entryFee,
        };
  
        //console.log(data);
        if(premiumUser){
          setCoinStore(false)
        }
        else{
          setIsBusy(true)
          let response = await getWalletLimit(data);
          //console.log("T Wallet Response: ", response?.item?.winningAmount >= response?.item?.entryFee
            // ? false
            // : true);
          if (response) {
            setCoinStore(
              response?.item?.winningAmount >= response?.item?.entryFee
                ? false
                : true
            );
            setWalletUsageData(response?.item);
            setCoin(response?.item?.winningAmount);
          }
        }
        // console.log(await checkDobNstateConditions(dobNstateScreenName, uniqueI))
        let dobConditionData = await checkDobNstateConditions(dobNstateScreenName, uniqueI)
        if (dobConditionData) {
          setShowdobnstate(true);
          // setShowdobnstateInfo(dobConditionData?.gameNamesData);
          setIsBusy(false)
        } else {
          setShowdobnstate(false);
          setcurrentName(uniqueI);
          setShowJoin(true);
          setIsBusy(false)
        }
      } else {
        toast.error(t('error_Oops_This_tournament_has_already_started'), {
          position: "top-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    // setFirstRoundMatchId("");
    // localStorage.setItem("joinTounament", JSON.stringify(allFeaturedData));
    // localStorage.setItem("activeGameId", allFeaturedData?.gameId);
    // localStorage.setItem("activeGame", allFeaturedData?.gameName);
    // var tempRound = allFeaturedData.roundDatetime?.[allFeaturedData.roundDatetime.length - 1];

    // var today = new Date();
    // var cdate = moment(tempRound.date).format("y-MM-DD");
    // var ctime = moment(tempRound.time).format("HH:mm:ss");
    // var contestDateTime = moment(cdate + " " + ctime);

    // var isShowTournament = true;
    // if (today > new Date(contestDateTime)) {
    //   isShowTournament = false;
    // }

    // if (isShowTournament) {
    //   var uniqueI = "";

    //   localStorage.setItem("tournamentId", allFeaturedData.id);
    //   uniqueI = allFeaturedData.inGameName;

    //   setUniqueIgn(uniqueI);

    //   let data = {
    //     event: allFeaturedData.id,
    //     type: "event",
    //     currencyCode: allFeaturedData?.currency?.inCurrency?._id,
    //     entryFee: allFeaturedData?.entryFee,
    //   };

    //   console.log(data);
    //   if(premiumUser){
    //     setCoinStore(false)
    //   }
    //   else{
    //     setIsBusy(true)
    //     let response = await getWalletLimit(data);
    //     console.log("T Wallet Response: ", response);
    //     if (response) {
    //       setCoinStore(
    //         response?.item?.winningAmount >= response?.item?.entryFee
    //           ? false
    //           : true
    //       );
    //       setWalletUsageData(response?.item);
    //       setCoin(response?.item?.winningAmount);
    //     }
    //   }

    //   if (await checkDobNstateConditions(dobNstateScreenName)) {
    //     setShowdobnstate(true);
    //     setIsBusy(false)
    //   } else {
    //     setShowdobnstate(false);
    //     setcurrentName(uniqueI);
    //     setShowJoin(true);
    //     setIsBusy(false)
    //   }
    // } else {
    //   toast.error(t('error_Oops_This_tournament_has_already_started'), {
    //     position: "top-left",
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };

  const onJoinTournament = async () => {
    if (clickCheck) {
      toast.error(t('tournaments.join_process_already_in_process_can_not_join_twice'), {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setClickCheck(true);
      setShowConfirm(false);
      if (coinStore == false) {
        //uniqueIgn
        // let games = JSON.parse(
        //   localStorage.getItem(constants.profileLite)
        // )?.item?.gameNames.find((val) => val?.game === allFeaturedData?.gameId);
        // var uniqueI = games?.uniqueIGN;
        // setUniqueIgn(uniqueI);
        // console.log("uniqueI=games=>", games);
        var user_data = JSON.parse(localStorage.getItem(constants.profileLite));
        // var uniqueI = allFeaturedData?.inGameName//games?.uniqueIGN;
       
        // setUniqueIgn(uniqueI);
        console.log("currency", uniqueIgn);
        let data = {
          event: allFeaturedData?.id,
          contest: contest,
          isActive: true,
          type: "event",
          currencyCode: allFeaturedData?.currency?.inCurrency?._id,
          inGameUserName: uniqueIgn,
          uniqueIGN: uniqueIgn,
          user: user_data?.item?.user_id,
        };
        //console.log("gameTypePlayers==>", allFeaturedData?.gameTypePlayers);
        // console.log("data==>", data);
        // {
        //   event: allFeaturedData?.id,
        //   contest: contest,
        //   isActive: true,
        //   type: "event",
        //   uniqueIGN:
        //     uniqueIgn === null || uniqueIgn === ""
        //       ? localStorage.getItem("uniqueIGName")
        //       : uniqueIgn,
        // };
        //console.log("data::", data);

        // Squad Registration
        if (
          allFeaturedData?.gameTypePlayers &&
          parseInt(allFeaturedData?.gameTypePlayers) > 1
        ) {
          var numArr = [];

          var selectedPlayerArr = [];
          for (var i = 1; i < parseInt(allFeaturedData?.gameTypePlayers); i++) {
            numArr.push(i);

            selectedPlayerArr.push({
              num: i,
              user_id: null,
              gamerjiName: null,
              levelImage: null,
              inGameName: null,
            });

            setSelectedPlayerData(selectedPlayerArr);
            // console.log("selectedPlayerData==>", selectedPlayerData);
            setNumPlayers(numArr);
            setShowJoinTeam(true);
            setTotal_players(selectedPlayerArr.length);
            setExtra_players(allFeaturedData?.gameTypeExtraPlayers);

            // console.log(
            //   "allFeaturedData: ",
            //   allFeaturedData.gameTypeExtraPlayers
            // );

            setShowJoinTeam(true);
          }
          // else {
          //   toast.error(constants.error_duo_squad_tournament_in_maintenance, {
          //     position: "top-left",
          //     autoClose: 2000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //   });
          // }
          // props.showJoinTeam(true, selectedPlayerArr, contest);
        } else {
          let res = await onCreateTournament(data);
          // console.log("T Join", res);
          if (res) {
            // console.log("joined", res);
            // setCreateData(res?.item);routehelp.tournamentdetail
            if (res?.success) {
              // console.log("joined");
              window.location.href = `/tounament-details/${allFeaturedData?.id}`;
            } else if(res?.data.errors[0]?.data == 'events.validation.MAX_FREE_TRANSACTION'){
              //console.log("er np", res?.data.errors[0]?.data);
              setShowPremiumPopup(true)
            } 
            else {
              //console.log("er", res?.data.errors[0]?.msg);
              toast.error(res?.data.errors[0]?.msg, {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              window.location.reload(false);
            }
          } else {
            //console.log("error", res.error);
            toast.error(res.errors?.[0]?.msg, {
              position: "top-left",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            
          }
        }
        setClickCheck(false);
      } else {
        setClickCheck(false);
        //window.location.href = routehelp.coinstore; 
        navigate("/" + routehelp.coinStore, { state: 1 })
      }
    }
  };

  const onSelectTime = async (item) => {
    let body = {
      code: item?.code
    }
    let response = await tournamentMatchesById(allFeaturedData?.id, body);
    if (response?.success) {
      setFirstRoundMatchId(item._id);
      setSelectedDate(getTournamentDateTime(item?.date, item?.time));
      let temp = [];
      temp = response?.item?.reduce((item, val1)=>{
        let data = {
          round: val1.name,
          date: val1?.matches?.[0]?.date,
          time:val1?.matches?.[0]?.time

        }
        item.push(data)
        return item
      },[])
      setRounds(temp);
      setContest(item._id);
    }
  };


  const onNext = () => {
    // console.log("firstRoundMatchId", firstRoundMatchId);
    if (firstRoundMatchId !== "") {
      setShowJoin(false);
      setShowConfirm(true);
    } else {
      toast.error(t('error_Please_Select_At_leaset_one_Match'), {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  let findUserName = (gameid) => {
    // console.log("gameid", gameid);
    let gameNames = JSON.parse(localStorage.getItem("userInGameNames"));
    if (gameNames) {
      let index = gameNames.findIndex((obj) => obj.game == gameid);
      if (index > -1) {
        setcurrentName(gameNames[index].userINGameName);
        // return gameNames[index].userINGameName || ""
      } else {
        return "";
      }
    }
  };

  const getdobnstate = async (tag) => {
    // setShowjoinpopup(tag);
    // console.log("game id ", tag);
    var gameid = localStorage.getItem("activeGameId");
    // findUserName(gameid);

    setShowdobnstate(tag);

    let uniqueI = allFeaturedData?.inGameName || await InGameNameApiCall();
  
    setUniqueIgn(uniqueI);

    setShowJoin(true);
    setIsBusy(false)
    // setShowJoin(true);
    // var gameid =
    //   window.location.href.split("/")[
    //   window.location.href.split("/").length - 1
    //   ];

    // let data = {}

    // gameid === 'all' ? data = { contest: selectedFeaturedData._id, type: "event" }
    // : data = { contest: selectedFeaturedData._id, type: "contest" };

    // let data = { contest: selectedFeaturedData._id, type: "contest" };
    // let response = await getWalletLimit(data);
    // console.log('Wallet Response: ', response);
    // if (response) {
    //   setCoinStore(
    //     response?.item?.coinAmount > response?.item?.entryFee ? false : true
    //   );

    //   setCoin(response?.item?.coinAmount);
    //   setWalletUsageData(response?.item);
    // }
  };

  const cancelDobNstate = () => {
    setShowdobnstate(false);
    // setShowjoinpopup(false);
    setShowJoin(false);
  };

  const backButtonSetShowJoinTeam = () => {
    setShowJoinTeam(false);
  };

  const showWinnersClick = async () => {
    let response = await getPrizePool(allFeaturedData?.id);
    if (response?.success) {
      setPrizePoolInfo(response?.item)
      setShowWinners(true);
    }
  }

  const showRulesClick = async () => {
    let response = await getRules(allFeaturedData?.id);
    if (response?.success) {
      setRulesInfo(response?.item)
      setShowRules(true);
    }
  }

  return (
    <>
      <div
        className="contest-list contest-tournament-list"
        id="tournament_main"
      >
        {/* {props?.joinCode === false && */}
        {/* {window.location.pathname === '/' && <ToastContainer />} */}
        {/* DOBNSTATE Popup */}
        {/* {console.log("showdobnstate", showdobnstate)} */}
        {showdobnstate ? (
          <DobNStates
            submitDobNstate={getdobnstate}
            cancelDobNstate={cancelDobNstate}
            screen={dobNstateScreenName}
            gameId={localStorage.getItem("activeGameId")}
            showdobnstateInfo={showdobnstateInfo}
          />
        ) : (
          ""
        )}

        {/* Tournament/all (all box), Join Via Invite Code (Codes for tournaments) Popup Box  */}
        <div className="box">
          <h3>
            {
              allFeaturedData?.title?.length > 32 ?   <marquee>{allFeaturedData?.title}</marquee> : allFeaturedData?.title
            }
          
            <span className="pointer" onClick={() => showRulesClick()}>
              <img
                src={Rule}
                alt=""
                style={{ height: "15px", marginRight: "3px" }}
              />{" "}
              { t('contest_details.rules') }
            </span>
          </h3>
          <div className="row tournament_contest_row">
            <div className="col-4 pr-1">
              <div className="img">
                <img
                  src={allFeaturedData?.img}
                  style={{ maxHeight: "135px" }}
                  alt=""
                />
                <div className="img-caption">
                  <h5>{allFeaturedData?.gameType}</h5>
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="inner_row pt-0 ">
                <div className="inner_item inner_item inner_item_medium">
                { t('tournaments.date') }{" "}
                  <b>
                    {getProfiledateFormat(allFeaturedData?.startDate)}
                    {/* to{" "}{dateFormat(allFeaturedData?.endDate)} */}
                  </b>
                </div>
                <div className="inner_item inner_item_medium">
                { t('tournaments.round') } <b>{allFeaturedData?.roundsCount}</b>
                </div>
                <div className="inner_item inner_item_medium">
                { t('tournaments.id') } <b>{allFeaturedData?.shortCode}</b>
                </div>
              </div>
              <div className="inner_row bg-primary">
                {allFeaturedData?.titles?.slice(0,2)?.map((item, index) => {
                  if (item?.name !== "-" && item?.isSelection) {
                    return (
                      <div
                        className="inner_item"
                        key={index}
                        style={{ width: "50%" }}
                      >
                        { item?.name } <b>{item?.value}</b>
                      </div>
                    );
                  }
                })}
              </div>
              <div className="inner_row">
                <div className="inner_item inner_item_medium">
                { t('tournaments.prize_pool') }{" "}
                 {allFeaturedData?.rewardDisplayText != null || allFeaturedData?.rewardDisplayText != undefined ?
                  <b style={{textOverflow: "ellipsis", whiteSpace:'nowrap', overflow:'hidden'}}>
                  {allFeaturedData?.rewardDisplayText}
                 </b>
                 :
                 <b>
                    {(allFeaturedData?.currency?.outCurrency?.code == "inr" ||
                      allFeaturedData?.currency?.outCurrency?.code == "INR") &&
                      "₹"}
                    {(allFeaturedData?.currency?.outCurrency?.code == "coin" ||
                      allFeaturedData?.currency?.outCurrency?.code ==
                        "diamond") && (
                      <img
                        src={
                          url.imageUrl +
                          allFeaturedData.currency.outCurrency.img.default
                        }
                        alt=""
                        className="coin_img"
                      />
                    )}
                    {/* <img src={allFeaturedData?.coin} className="coin_img" />{" "} */}{" "}
                    {allFeaturedData?.prizePool}
                  </b>
                }
                </div>
                <div className="inner_item inner_item_medium ">
                  { t('tournaments.winners') }{" "}
                  <b className="pointer" onClick={() => showWinnersClick()}>
                    {" "}
                    {allFeaturedData?.winners}{" "}
                    <i className="fas fa-chevron-down"></i>
                  </b>
                </div>
                <div className="inner_item inner_item_medium">
                { t('tournaments.join_using') } <br />
                  {allFeaturedData?.entryFee > 0 ? (
                    <b>
                      {(allFeaturedData?.currency?.inCurrency?.code == "inr" ||
                        allFeaturedData?.currency?.inCurrency?.code == "INR") &&
                        "₹"}
                      {(allFeaturedData?.currency?.inCurrency?.code == "coin" ||
                        allFeaturedData?.currency?.inCurrency?.code ==
                          "diamond") && (
                        <img
                          src={
                            url.imageUrl +
                            allFeaturedData.currency.inCurrency.img.default
                          }
                          alt=""
                          className="coin_img"
                        />
                      )}{" "}
                      {allFeaturedData?.entryFee}
                    </b>
                  ) : (
                    <b>{ t('tournaments.free') }</b>
                  )}
                </div>
              </div>
              <div className="avalible_wrap">
                <div className="inner">
                  <span style={{ width: progressWidth }}></span>
                </div>
                <p className="d-flex justify-content-between">
                  <span>
                    {t('tournaments.players_remaining', {players:`${allFeaturedData?.totalPlayers -
                      allFeaturedData?.totalJoinedPlayers}`})}
                  </span>
                  <span>
                    {t('tournaments.players_joined', {players:`${allFeaturedData?.totalJoinedPlayers}`})}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="contest_foot p-2">
            <div className="inner pl-2">
              <span>{ t('private_contest.hosted_by') }</span>
              <b>
                {" "}
                <marquee>
                  {allFeaturedData?.hostedby} (
                  {Number(allFeaturedData?.hostrate?.toFixed(2))}&nbsp;
                  <i
                    className="fas fa-star text-dark"
                    style={{ fontSize: "80%" }}
                  ></i>
                  )
                </marquee>
              </b>
            </div>
            {allFeaturedData?.totalJoinedPlayers >=
            allFeaturedData?.totalPlayers ? (
              <div className="inner pointer">
                <a className="">{ t('tournaments.full') }</a>
              </div>
            ) : allFeaturedData?.isJoined === true ? (
              <div className="inner pointer">
                <Link
                  to={`/tounament-details/${allFeaturedData?.id}`}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "25px" }}
                  >
                    { t('tournaments.joined') }
                  </div>

                  <div
                   className="tick-icon"
                  >
                    <Lottie
                      options={{
                        animationData: successCheck,
                        w: "20px",
                      }}
                    />
                  </div>
                </Link>
              </div>
            ) : (
              <button 
              style={{backgroundColor :'transparent', outline:'none', border:'none'}}     
              className="inner pointer text-center d-flex flex-row justify-content-center align-items-center" 
              disabled = {(isBusy || clickCheck)}
              onClick={() => getjoin()}>
                <a className="contest_joining_popup text-center">{ t('tournaments.join_now') } 
                  {
                    (isBusy || clickCheck) && <img style={{ maxWidth: 20 }} src={roller} />
                  }
                </a>
              </button>
            )}
          </div>
        </div>
        {/* } */}

        {/* Winning Breakup - Tournaments */}
        {showWinners === true && (
          <div className="tc_popup_bg">
            <div
              className="custom-popup rules custom_scroll show"
              id="contact_popup"
            >
              <div className="popup-head">
                <h3>{ t('contest_details.winning_breakup') }</h3>
                <button
                  className="close-popup"
                  onClick={() => setShowWinners(false)}
                >
                  <img src={Close} alt="" />
                </button>
              </div>
              <div className="popup-body bg-yellow">
                <div className="all_sub_screens_div_related_to_ads">
                  {prizePoolInfo?.pointpool &&
                    prizePoolInfo?.pointpool?.length !== 0 && (
                      <div
                        className="list-group main-tab w-90"
                        id="list-tab"
                        role="tablist"
                      >
                        <a
                          className="list-group-item list-group-item-action active"
                          id="list-home-list"
                          data-toggle="list"
                          href="#list-1"
                        >
                          { t('tournaments.prize_pool') }
                        </a>
                        <a
                          className="list-group-item list-group-item-action"
                          id="list-profile-list"
                          data-toggle="list"
                          href="#list-2"
                        >
                          { t('contest_details.points') }
                        </a>
                      </div>
                    )}

                  <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane active" id="list-1">
                      <div className="prize_pool mb-3 text-center p-2 w-90">
                        <p>{ t('tournaments.prize_pool') }</p>
                       {allFeaturedData?.rewardDisplayText !=null ?
                       <h6 style={{textOverflow: "ellipsis", whiteSpace:'nowrap', overflow:'hidden'}}>
                       {allFeaturedData?.rewardDisplayText}
                     </h6>
                       :
                        <h6>
                            {(allFeaturedData?.currency?.outCurrency?.code ==
                              "inr" ||
                              allFeaturedData?.currency?.outCurrency?.code ==
                                "INR") &&
                              "₹"}
                            {(allFeaturedData?.currency?.outCurrency?.code ==
                              "coin" ||
                              allFeaturedData?.currency?.outCurrency?.code ==
                                "diamond") && (
                              <img
                                src={
                                  url.imageUrl +
                                  allFeaturedData.currency.outCurrency.img.default
                                }
                                alt=""
                                className="coin_img_big"
                              />
                              )}{" "}
                          {allFeaturedData?.prizePool}
                        </h6>}
                      </div>
                      <div className="table-responsive total_table total_table_border mb-3 w-90">
                        <table>
                          <tbody>
                            {prizePoolInfo?.prizePooInfo?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="text-left">
                                  { t('contest_details.rank') }:{" "}
                                    {item?.rankTo !== "0" &&
                                    item?.rankTo != item?.rankFrom
                                      ? item?.rankFrom + "-" + item?.rankTo
                                      : item?.rankFrom}
                                  </td>
                                  <th className="text-right">
                                    {allFeaturedData?.rewardDisplayText != null ?
                                      <></> :
                                      <>
                                        {(allFeaturedData?.currency?.outCurrency
                                          ?.code == "inr" ||
                                          allFeaturedData?.currency?.outCurrency
                                            ?.code == "INR") &&
                                          "₹"}
                                        {(allFeaturedData?.currency?.outCurrency
                                          ?.code == "coin" ||
                                          allFeaturedData?.currency?.outCurrency
                                            ?.code == "diamond") && (
                                            <img
                                              src={
                                                url.imageUrl +
                                                allFeaturedData.currency?.outCurrency
                                                  ?.img?.default
                                              }
                                              alt=""
                                              className="coin_img"
                                            />
                                          )}{" "}
                                      </>
                                    }
                                    {item?.amount || item?.prize}
                                  </th>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {prizePoolInfo?.pointpool && (
                      <div className="tab-pane" id="list-2">
                        <div className="table-responsive total_table total_table_border mb-3 w-90">
                          <table>
                            <tbody>
                              {prizePoolInfo?.pointpool?.map(
                                (item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="text-left">
                                      { t('contest_details.rank') }:{" "}
                                        {item?.rankTo !== "0" &&
                                        item?.rankTo != item?.rankFrom
                                          ? item?.rankFrom + "-" + item?.rankTo
                                          : item?.rankFrom}
                                      </td>
                                      <th className="text-right">
                                        {item?.point}
                                      </th>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {
                  premiumUser && <Advertisement
                  screen={
                    localStorage.getItem("apk_screens_list") === null
                      ? { code: "no_results" }
                      : JSON.parse(localStorage.getItem("apk_screens_list"))
                          .prizePoolPopup
                  }
                  screen_div_class_name=".all_sub_screens_div_related_to_ads"
                  screen_type="sub"
                />
                }
                
              </div>
            </div>
          </div>
        )}

        {/* Rules - Tournaments */}
        {showRules === true && (
          <div className="tc_popup_bg">
            <div
              className="custom-popup rules custom_scroll show"
              id="contact_popup"
            >
              <div className="popup-head">
                <h3>{ t('contest_details.rules') }</h3>
                <button
                  className="close-popup"
                  onClick={() => setShowRules(false)}
                >
                  <img src={Close} alt="" />
                </button>
              </div>
              <div className="popup-body bg-yellow">
                <p className="text-left">{ReactHtmlParser(rulesInfo?.rules)}</p>
              </div>
            </div>
          </div>
        )}

        {/* Join Popup - Click on Join Now (any box) - tournamentTimingPopup */}
        {showJoin === true && (
          <div className="tc_popup_bg 1">
            <div
              className={`custom-popup  confirm custom_scroll join show ${
                premiumUser ? "premium_active" : "rules"
              }`}
            >
              <div
                className={`popup-head ${premiumUser ? "premium_active" : ""}`}
              >
                {premiumUser ? (
                  <>
                    <b>
                      {/* {console.log('Test: ', allFeaturedData)} */}
                      {/* {allFeaturedData?.gameName} - {allFeaturedData?.gameType} */}
                      {allFeaturedData?.title}
                    </b>
                  </>
                ) : (
                  <>
                  {
                    allFeaturedData?.title?.length>36 ? 
                    <marquee>
                      <h3>{allFeaturedData?.title}</h3>
                    </marquee>
                    : <h3>{allFeaturedData?.title}</h3>
                  }
                    
                  </>
                )}

                <button
                  className="close-popup"
                  onClick={() => setShowJoin(false)}
                >
                  <img src={premiumUser ? PremiumClose : Close} alt="" />
                </button>
              </div>
              
              <div
                className={`popup-body ${
                  premiumUser ? "premium_active" : "bg-yellow"
                }`}
              >
                <div className="all_sub_screens_div_related_to_ads">
                  {premiumUser ? (
                    <>
                      <p
                        className={`game_det ${
                          premiumUser ? "premium_active" : ""
                        }`}
                        style={{ fontWeight: "100" }}
                      >
                        <span>
                          {allFeaturedData?.gameName} -{" "}
                          {allFeaturedData?.gameType}
                        </span>
                        <span>
                          {getTournamentDateTime(
                            allFeaturedData?.startDate,
                            allFeaturedData?.endDate
                          )}
                        </span>
                      </p>
                    </>
                  ) : (
                    <>
                      <b>
                        {/* {console.log('Test: ', allFeaturedData)} */}
                        {allFeaturedData?.gameName} -{" "}
                        {allFeaturedData?.gameType}
                      </b>
                      <p className="error">
                        {dateFullFormat(allFeaturedData?.startDate)} { t('tournaments.to') }{" "}
                        {dateFullFormat(allFeaturedData?.endDate)}
                      </p>
                    </>
                  )}

                  {premiumUser ? (
                    <>
                      <div
                        className="ps_info"
                        style={{
                          height: "60px",
                          justifyContent: "center",
                          margin: "10px auto",
                        }}
                      >
                        <div
                          className="ps_info_detail"
                          style={{ textAlign: "center", fontSize: "16px" }}
                        >
                          {t('tournaments.select_1st_round_time',{n:`1st`})}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <p
                        className="round_time mb-3"
                        style={{ fontSize: "18px", fontWeight: "600" }}
                      >
                        {t('tournaments.select_1st_round_time',{n:`1st`})}
                      </p>
                    </>
                  )}

                  <div className="radio_wrap custom_scroll">
                    {contestList?.map((item, index) => {
                      {
                        /*   var tempContest = item1
                      console.log("tempContest=>", tempContest); */
                      }
                      // {allFeaturedData?.roundDatetime?.map((item, index) => {
                      //   console.log('Date-Time: ', item)
                      //   var today = new Date();
                      //   var cdate = moment(item?.date).format('y-MM-DD');
                      //   var ctime = moment(item?.time).format('HH:mm:ss');
                      //   var contestDateTime = moment(cdate + ' ' + ctime);
                      //   if (item.matchId == allFeaturedData?.contestsList[i].code) {
                      //     console.log('Date-Time: inner ', item)
                      //     console.log('Date-Time: contestDateTime ', contestDateTime)
                      //     console.log('Date-Time: today ', today)

                      //   if (today < new Date(contestDateTime)) {
                      //     console.log('Date-Time: inner 1 ', item)

                      return (
                        <label
                          key={index}
                          className={`${
                            premiumUser ? "border_white" : "border_black"
                          }`}
                        >
                          <input type="radio" name="radio" onClick={() => onSelectTime(item)} />
                          <span
                            className={`${premiumUser ? "ps_radio" : ""} `}
                            style={{
                              border: premiumUser ? "1px solid #fff" : "",
                            }}
                          ></span>
                          <p style={{ color: premiumUser ? "#fff" : "#000" }}>
                            {getTournamentFullDateTime(item?.date, item?.time)}
                          </p>
                        </label>
                      );
                      //     }
                      //     }
                      //   })
                      // }
                    })}
                  </div>

                  {firstRoundMatchId === "" ? (
                    <b className="select_msg my-3">
                      {" "}
                      {t('tournaments.select_match_to_check_next_round_timing')}{" "}
                    </b>
                  ) : (
                    <div className={`rounds ${premiumUser ? "round1" : ""}`}>
                      <OwlCarousel
                        className="owl-carousel owl-theme"
                        autoWidth={false}
                        items={1}
                        loop
                        margin={0}
                        // stagePadding={50}
                        autoplay={false}
                        dots={false}
                        nav={true}
                        // navText={[
                        //   <i className="fa fa-arrow-left" aria-hidden="true">hhh</i>,
                        //   <i className="fa fa-arrow-right" aria-hidden="true"></i>,
                        // ]}
                      >
                        {rounds?.map((item, index) => {
                          return (
                            <div className="item" key={index}>
                              <p
                                className="round_name"
                                style={{ color: premiumUser ? "#fff" : "#000" }}
                              >
                                <b>{item?.round}</b>
                              </p>
                              <p
                                className="round_name"
                                style={{ color: premiumUser ? "#fff" : "#000" }}
                              >
                                <b>
                                  {getTournamentFullDateTime(
                                    item?.date,
                                    item?.time
                                  )}
                                </b>
                              </p>
                            </div>
                          );
                        })}
                      </OwlCarousel>
                    </div>
                  )}

                  <p
                    className="finale mt-3"
                    style={{ color: premiumUser ? "#fff" : "#000" }}
                  >
                    <b>
                    {t('tournaments.finale_will_be_scheduled_at',{date:`${getTournamentFullDateTime(
                        finaleData?.date,
                        finaleData?.time
                      )}`})}
                      {/* {console.log("finaleData::", finaleData)} */}
                      
                      {/* {getTournamentFullDateTime(
                        allFeaturedData?.endDate,
                        allFeaturedData?.endDate
                      )} */}
                    </b>
                  </p>

                  <button
                    className={`btn  main-btn next ${
                      premiumUser ? "premium_active_btn" : "btn-dark"
                    }`}
                    onClick={() => onNext()}
                  >
                    {t('tournaments.next')}{" "}
                    <img src={premiumUser ? rightArrowBlack : rightArrow} alt="" className="mCS_img_loaded" />
                  </button>
                </div>
                {
                  !premiumUser && 
                
                <Advertisement
                  screen={
                    localStorage.getItem("apk_screens_list") === null
                      ? { code: "no_results" }
                      : JSON.parse(localStorage.getItem("apk_screens_list"))
                          .tournamentTimingPopup
                  }
                  screen_div_class_name=".all_sub_screens_div_related_to_ads"
                  screen_type="sub"
                />
}
              </div>
            </div>
          </div>
        )}

        {/* Join Popup - Click on Join Now (any box) - joinContestWalletPopup */}
        {showConfirm === true && (
          <div className="tc_popup_bg 2">
            <div
              className={`custom-popup  confirm custom_scroll join show ${
                premiumUser ? "premium_active" : "rules"
              }`}
            >
              <div
                className={`popup-head ${premiumUser ? "premium_active" : ""}`}
              >
                {premiumUser ? (
                  <h3>{allFeaturedData?.title}</h3>
                ) : (
                  <marquee>
                    <h3>{allFeaturedData?.title}</h3>
                  </marquee>
                )}

                <button
                  className="close-popup"
                  onClick={() => setShowConfirm(false)}
                >
                  <img src={premiumUser ? PremiumClose : Close} alt="" />
                </button>
              </div>
              <div
                className={`popup-body ${
                  premiumUser ? "premium_active" : "bg-yellow"
                }`}
              >
                <div className="all_sub_screens_div_related_to_ads">
                  <p
                    className={`game_det ${
                      premiumUser ? "premium_active" : ""
                    }`}
                  >
                    <span>
                      {allFeaturedData?.gameName} - {allFeaturedData?.gameType}
                    </span>
                    <span>{selectedDate}</span>
                  </p>

                  {premiumUser ? (
                    <>
                      <div className="ps_info">
                        <div className="ps_info_logo">
                          <img src={PremiumLogo} /> 
                        </div>
                        <div className="ps_info_detail">{t('as_a_Gamerji_Premium_User_you_can_join_unlimited_tournaments_for_free')}</div>
                      </div>
                    </>
                  ) : (
                    <div className="round_time">
                      <p>{t('tournaments.confirmation')}</p>
                      {(allFeaturedData.currency?.inCurrency?.code == "coin" ||
                        allFeaturedData.currency?.inCurrency?.code ==
                          "diamond") && (
                        <p>
                          {t('tournaments.coin_balance')} ={" "}
                          <img
                            src={
                              url.imageUrl +
                              allFeaturedData.currency.inCurrency.img.default
                            }
                            style={{ width: "15px" }}
                          />{" "}
                          {coin}{" "}
                        </p>
                      )}
                      {(allFeaturedData.currency?.inCurrency?.code == "inr" ||
                        allFeaturedData.currency?.inCurrency?.code ==
                          "INR") && (
                        <p>Balance = ₹ {walletUsage.walletBalance} </p>
                      )}
                    </div>
                  )}

                  <div className="fee">
                    <p
                      className={`${
                        premiumUser ? "premium_active border_white" : ""
                      }`}
                    >
                      <b>{t('tournaments.entry_fee')}</b>
                      {allFeaturedData.entryFee === 0 ? (
                        <b>{t('tournaments.free')}</b>
                      ) : allFeaturedData.currency?.inCurrency?.code == "inr" ||
                        allFeaturedData.currency?.inCurrency?.code == "INR" ? (
                        <b>
                          {"₹ "} {allFeaturedData.entryFee}
                        </b>
                      ) : (
                        <b>
                          <img
                            src={
                              url.imageUrl +
                              allFeaturedData.currency.inCurrency.img.default
                            }
                            style={{ width: "15px" }}
                          />{" "}
                          {allFeaturedData.entryFee}
                        </b>
                      )}
                    </p>
                    <p
                      className={`d-flex ${
                        premiumUser ? "premium_active border_white" : ""
                      }`}
                      style={{ justifyContent: "space-between" }}
                    >
                      <span>
                        {/* <img src={pay} alt="" className="mCS_img_loaded mr-2" /> */}
                        {premiumUser ? t('private_contest.your_fee') : t('tournaments.to_pay')}
                      </span>
                      {premiumUser ? (
                        " Free"
                      ) : allFeaturedData.entryFee === 0 ? (
                        <b>{t('tournaments.free')}</b>
                      ) : allFeaturedData.currency?.inCurrency?.code == "inr" ||
                        allFeaturedData.currency?.inCurrency?.code == "INR" ? (
                        <b>
                          {"₹ "}
                          {allFeaturedData.entryFee}
                        </b>
                      ) : (
                        <b>
                          <img
                            src={
                              url.imageUrl +
                              allFeaturedData.currency.inCurrency.img.default
                            }
                            style={{ width: "15px" }}
                          />{" "}
                          {allFeaturedData.entryFee}
                        </b>
                      )}
                    </p>
                  </div>
                  {allFeaturedData?.currency?.joiningNote != "" && (
                    <p className="terms_join" style={{ fontSize: "14px" }}>
                      {allFeaturedData?.currency?.joiningNote}
                    </p>
                  )}
                  {allFeaturedData.levelNote != "" && (
                    <p className="level_note">{allFeaturedData.levelNote}</p>
                  )}
                  {premiumUser && allFeaturedData.entryFee > "0" ? (
                    <>
                      {" "}
                      <div className="ps_coin_saved">
                        <p>
                          <b>
                            {" "}
                            {t('tournaments.saved',{coin: `${
                              allFeaturedData.currency?.inCurrency?.code[0].toUpperCase() + allFeaturedData.currency?.inCurrency?.code.slice(1)
                            }`})}
                          </b>
                          <b>
                            {
                              (allFeaturedData?.contestFor?.inCurrencyInfo
                                ?.code == "INR" ||
                              allFeaturedData?.contestFor?.inCurrencyInfo
                                ?.code == "inr" ? (
                                "₹"
                              ) : (
                                <img
                                  src={
                                    url.imageUrl +
                                    allFeaturedData.currency.inCurrency.img
                                      .default
                                  }
                                  style={{ width: "14px", height:'14px' }}
                                  className="coin_img"
                                />
                              ))}{" "}
                            {allFeaturedData.entryFee === "0"
                              ? "0"
                              : allFeaturedData.entryFee}
                          </b>
                        </p>
                      </div>{" "}
                    </>
                  ) : (
                    <></>
                  )}
                  <button
                    className={`btn  main-btn next ${
                      premiumUser ? "premium_active_btn" : "btn-dark"
                    }`}
                    onClick={() => onJoinTournament()}
                  >
                    {t('private_contest.join_tournament')}{" "}
                    <img src={premiumUser ? rightArrowBlack : rightArrow} alt="" className="mCS_img_loaded" />
                  </button>
                </div>
                {
                  !premiumUser && 
                
                <Advertisement
                  screen={
                    localStorage.getItem("apk_screens_list") === null
                      ? { code: "no_results" }
                      : JSON.parse(localStorage.getItem("apk_screens_list"))
                          .joinContestWalletPopup
                  }
                  screen_div_class_name=".all_sub_screens_div_related_to_ads"
                  screen_type="sub"
                />
}
              </div>
            </div>
          </div>
        )}

        {showPremiumPopup === true && (
          <div className="tc_popup_bg 3">
            <div
              className={`custom-popup  confirm custom_scroll join show premium_active rules`}
              id="contact_popup"
            >
              <div className={`popup-head  premium_active`}>
                {/* <marquee> */}
                <h3> {allFeaturedData?.title} </h3>
                {/* </marquee> */}

                <button
                  className="close-popup"
                  onClick={() => setShowPremiumPopup(false)}
                >
                  {/* {console.log(premiumUser)} */}
                  <img src={PremiumClose} alt="" />
                </button>
              </div>
              <div className="ps_header" style={{ height: "150px" }}>
                <div className="ps_logo">
                  <img src={Logo} />
                </div>
                <div className="ps_name">{t('tournaments.premium')}</div>
              </div>
              <div className="ps_popup_main">
                <div className="ps_popup_p">
                  <p>{t('premium.you_have_already_joined_today',{title: `tournament`, total: localStorage?.getItem('noOfTournament') || 1})}</p>
                </div>
                <div className="ps_popup_p layer2">
                  <p>{t('premium.to_join_a_tournament')},</p>
                  <p>{t('premium.subscribe_to_gamerji')}</p>
                </div>
                <div className="ps_popup_p">
                  <p>{t('premium.premium_users_get_to_join_unlimited_contests_tournaments_for_free_and_many_more_other_benefits')}</p>
                </div>
              </div>

              <button
                className={`btn premium_active_btn`}
                onClick={() => navigate(routehelp.premiumSubscription)}
                style={{
                  textAlign: "center",
                  width: "80%",
                  margin: "0 auto",
                  marginBottom: "30px",
                }}
              >
                {t('sidebar.explore_premium')}
              </button>
            </div>
          </div>
        )}

        {/* SQAUD registrations */}
        {showJoinTeam === true && (
          <SquadRegistrationTournaments
            selectedPlayerData={selectedPlayerData}
            selectedContest={contest}
            backButtonSetShowJoinTeam={backButtonSetShowJoinTeam}
            total_players={total_players}
            extra_players={extra_players}
          />
        )}
      </div>
    </>
  );
}

export default TournamentsStructure;
