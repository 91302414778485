import React, { useEffect, useState } from 'react'
import colors from "../../constants/colors";
import Success from "../../assets/images/success-payment.png";
import Fail from "../../assets/images/fail-payment.png";
import Pending from "../../assets/images/pending-payment.png";

import { useNavigate } from 'react-router';
import Previous from "../../assets/images/previous.svg";
import { useSearchParams } from 'react-router-dom';
import Moment from 'react-moment';
import { logout } from '../../utils/logout'

const PayUSuccess = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const [data, setData] = useState()
  const [user, setUser] = useState(null)
  const [verified, setVerified] = useState(false)
  useEffect(() => {
    const status = searchParams.get('status')
    const user = searchParams.get('user')
    setUser(user.toString())
    const txnid = searchParams.get('txnid')
    const message = searchParams.get('message')
    const amount = searchParams.get('amount')
    const paymentTimestamp = searchParams.get('paymentTimestamp')
    // const productId = searchParams.get('productId')
    // const productInfo = searchParams.get('productinfo')
    let payload = {
      status,
      txnid,
      message,
      amount,
      paymentTimestamp,


    }
    setData(payload)
    console.log(payload)
  }, [])

  useEffect(() => {
    if (user != null) {
      if (user != "undefined" && user != undefined && user != "error") {
        let profileLite = localStorage.getItem('profileLite')
        profileLite = JSON.parse(profileLite)
        // console.log("profileLite", profileLite?.item?.user_id)

        if (profileLite && profileLite?.item?.user_id && profileLite?.item?.user_id.toString() === user) {
          setVerified(true)
        }
        else {
          logout();
        }
      }
      else {
        navigate('/')
      }

    }

  }, [user])
  return (
    <div className="inner-wrap">

      <div className="header">
        <div className="back-btn">
          <a onClick={() => navigate('/')}>
            <img src={Previous} alt="" />
          </a>
        </div>
        <h2 style={{ textTransform: 'capitalize' }}>Payment Status</h2>
        <div className="wallet-btn"></div>
      </div>
      <div className="body mCustomScrollbar _mCS_1 bottom-btn-body"
        style={{ paddingTop: '20px' }}
      >
        {verified && 
          data?.status == "SUCCESS" ?

            <div style={{ backgroundColor: "#36DE4C", textAlign: 'center', height: "100%", position: "relative" }} className="mt-0">




              {/* Status Icon */}
              <img style={{ marginTop: 42, height: 100, width: 100 }} src={Success} />

              {/* Title */}
              <div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Success!</div>

              {/* Amount */}
              {
                <div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >
                  {/* {coinStore?.currency?.symbol} {coinStore?.item?.amount} */}
                  {"₹ "}
                  {data?.amount}
                </div>
              }

              {/* Date Time */}
              <div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} >
                {/* <Moment format="DD/MM/YYYY">
			{data?.paymentTimestamp}
		</Moment>   |  <Moment format="hh:mm A">
				{data?.paymentTimestamp}
			</Moment> */}
                {data?.paymentTimestamp}
              </div>

              {/* Transaction ID */}
              {
                <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Transaction ID :
                  #{data?.txnid}
                </div>
              }
              {/* <Text style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon! {paymentStatus}</Text> */}
              {/* Payment Status */}
              {
                data?.message !== undefined && data?.message !== "undefined" &&
                <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >{data?.message}</div>
              }


              {/* Done */}
              <button className="btn btn-dark main-btn next mt-4 mb-4 ml-2" style={{ width: "96%", position: "absolute", bottom: 0 }}
                onClick={() => navigate('/')}
              >DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


            </div>
            : data?.status == "FAILURE" ?
              <div style={{ backgroundColor: "#FF4646", textAlign: 'center', height: "100%", position: 'relative' }} className="mt-0">




                {/* Status Icon */}
                <img style={{ marginTop: 42, height: 100, width: 100 }} src={Fail} />

                {/* Title */}
                <div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Failure!</div>

                {/* Amount */}
                {
                  <div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >
                     {"₹ "}
                     {data?.amount}
                  </div>
                }

                {/* Date Time */}
                <div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} >
                  {
                    data?.paymentTimestamp
                  }
                </div>

                {/* Transaction ID */}
                {
                  <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Transaction ID : #{data?.txnid}</div>
                }
                {/* <Text style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon! {paymentStatus}</Text> */}
                {/* Payment Status */}
                {/* <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon!</div>
   */}

                {/* Done */}
                <button className="btn btn-dark main-btn next mt-4 mb-4 ml-2" style={{ width: "96%", position: "absolute", bottom: 0 }} onClick={() => navigate('/')}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


              </div>
              :
              <div style={{ backgroundColor: "#FF9544", textAlign: 'center', height: "100%", position: 'relative' }} className="mt-0">




                {/* Status Icon */}
                <img style={{ marginTop: 42, height: 100, width: 100 }} src={Pending} />

                {/* Title */}
                <div style={{ marginTop: 34, color: colors.black, fontSize: 36 }} >Pending</div>

                {/* Amount */}

                {
                  (data?.amount !== undefined && data?.amount !== "undefined") &&
                  <div style={{ marginTop: 25, color: colors.black, fontSize: 30 }} >
                    {/* {coinStore?.currency?.symbol} {coinStore?.item?.amount} */}
                    {"₹ "}
                    {data?.amount}
                  </div>

                }

                {/* Date Time */}
                {
                  data?.paymentTimestamp !== undefined && data?.paymentTimestamp !== "undefined" &&
                  <div style={{ marginTop: 8, color: colors.black, fontSize: 14 }} >
                    {/* <Moment format="DD/MM/YYYY">
			{data?.paymentTimestamp}
		</Moment>   |  <Moment format="hh:mm A">
				{data?.paymentTimestamp}
			</Moment> */}
                    {data?.paymentTimestamp}
                  </div>
                }

                {/* Transaction ID */}
                {
                  data?.txnid !== undefined && data?.txnid !== "undefined" ?
                    <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Transaction ID :
                      #{data?.txnid}
                    </div>
                    : ""
                }
                {/* <Text style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >Your coin balance will be credited soon! {paymentStatus}</Text> */}
                {/* Payment Status */}
                {
                  data?.message !== undefined && data?.message !== "undefined" &&
                  <div style={{ marginTop: 16, color: colors.black, fontSize: 14 }} >{data?.message}</div>
                }


                {/* Done */}
                <button className="btn btn-dark main-btn next mt-4 mb-4 ml-2" style={{ width: "96%", position: 'absolute', bottom: 0 }} onClick={() => navigate('/')}>DONE <img src="/static/media/right-arrow.eb845750.svg" alt="" className="mCS_img_loaded" /></button>


              </div>
        }
      </div>
    </div>
  )
}

export default PayUSuccess