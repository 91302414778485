import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getUserProfile,

  requestGameStats,
  getCollegeList,

  renewDetails,
  isUserPremium,
  getUserProfileLite,
  userRenewDetails,
} from "../../apis";
import Loader from "../loader";
import { logout } from "../../utils/logout";
const ProfileContext = createContext();

export const useProfile = () => {
  return useContext(ProfileContext);
};

export const ProfileProvider = ({ children }) => {
  const [allData, setAllData] = useState({});
  const [loading, setLoading] = useState(false);
  const [infoTitle, setInfoTitle] = useState("");
  const [collegeList, setCollegeList] = useState(null);
  const [levelProgressValue, setLevelProgressValue] = useState(0);
  const [gameStatsList, setGameStatsList] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [infoDesc, setInfoDesc] = useState("");
  const [activeTab, setActiveTab] = useState("Stats");
  const [walletDetails, setWalletDetails] = useState(null);
  const [renewDetailsData, setRenewDetailsData] = useState()
  const [premiumUserDetails, setPremiumUser] = useState(null)
  const [renderChild, setRenderChild] = useState(false)
  // useEffect(() => {
  //   fetchData();
  // }, []);

  const HandleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const topLinkClick = (value) => {
    window.location.href = value;
  };

  const getTotalCoin = (wallet) => {
    let index = wallet.findIndex((obj) => obj.currencyData?.code == "coin");

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
  };

  const getTotalDiamond = (wallet) => {
    let index = wallet.findIndex((obj) => obj.currencyData?.code == "diamond");

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
  };

  const fetchData = async () => {
    setLoading(true);
    let res = await getUserProfile();
    if (res) {
      setAllData(res);
      // console.log("ALL_Data: ", res);
      localStorage.setItem("currentCoin", getTotalCoin(res?.item?.wallet));
      localStorage.setItem(
        "currentDiamond",
        getTotalDiamond(res?.item?.wallet)
      );
      if (res.item.level) {
        let currentLevelPoints =
          res.item.level?.level?.endPoint -
          (res.item.level?.level?.startPoint - 1);

        let userPointsAsPerLevel =
          res.item.level?.points - (res.item.level?.level?.startPoint - 1);

        let lpVal = (userPointsAsPerLevel * 100) / currentLevelPoints;

        setLevelProgressValue(lpVal);
      }
      if (res?.item?.gameNames && res?.item?.gameNames.length > 0) {
        fetchGameStats(res?.item?.gameNames);
      }
      // call college list api
      let collegeList = await getCollegeList();
      if (collegeList) {
        // console.log("College: ", collegeList.list);
        setCollegeList(collegeList?.list);
      }
      setLoading(false);
    }
  };

  const fetchGameStats = async (gameNames) => {
    setLoading(true);
    let arrGameStats = [];
    let games = [];
    let gameNamesArr = [];
    if (gameNames && gameNames.length > 0) {
      gameNames.forEach(async function (element) {
        console.log("element==>", element);
        if (element.uniqueIGN != "" && element.uniqueIGN != undefined) {
          games.push(element.game);
          gameNamesArr[element.game] = element.uniqueIGN;
        }
      });

      let res = await requestGameStats({ games: games, appType: "webapp" });

      if (res && res.list) {
        var gameDataArr = [];
        res.list.forEach(async function (element, index) {
          var gameDataDateArr = [];
          var gameDataPlayedArr = [];
          element.stateatistic.forEach(function (element1) {
            var d = new Date(element1.date);

            gameDataDateArr.push(element1.date);

            gameDataPlayedArr.push(element1.played);
          });
          gameDataArr.push({
            avgRates: element.avgRates,
            game: element.game,
            played: element.played,
            uniqueIGN: gameNamesArr[element.game._id.toString()],
            data: {
              labels: gameDataDateArr,
              datasets: [
                {
                  label: "Played " + element.played,
                  data: gameDataPlayedArr,
                  borderColor: "rgb(255, 198, 9)",
                  backgroundColor: "rgb(255, 198, 9)",
                },
              ],
            },
          });
        });

        setGameStatsList(gameDataArr);
      }
      setLoading(false);
    }
  };

  const onClickInfo = (title, desc) => {
    setShowInfo(true);
    setInfoTitle(title);
    setInfoDesc(desc);
  };

  const options = {
    responsive: true,
    fill: true,
    scales: {
      y: {
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: 5,
        
      },
    },
    plugins: {
      legend: {
        position: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  const fetchLiteProfile = async()=>{
    setLoading(true)
    try{
      const res = await getUserProfileLite()
      // console.log("ress", res)
     
      if(res?.success){
        const user_id = res?.item?.user_id
        setWalletDetails(res?.item)
        // console.log(user_id)
        localStorage.setItem('profileLite', JSON.stringify(res))
        localStorage.setItem("userCountryObj", JSON.stringify(res?.item?.country));
        // if (res?.item?.isDetailsFilled === false) {
        //   window.location.href = '/sign-up';
        // }
        if (res?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || res?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
        logout()
        fetchUserPremiumDetails(user_id)
      
      }
      else{
        setLoading(false)
        console.log("error", res?.data)
      }
    }
    catch(error){
      setLoading(false)
      console.log(error);
    }
  }
  const updateWalletDetails = async()=> {
    // fetchLiteProfile()
    setLoading(true)
    try{
      const res = await getUserProfileLite()
      console.log("ress", res)
     
      if(res?.success){
        const user_id = res?.item?.user_id
        setWalletDetails(res?.item)
        // console.log(user_id)
        localStorage.setItem('profileLite', JSON.stringify(res))
        localStorage.setItem("userCountryObj", JSON.stringify(res?.item?.country));
        // localStorage.setItem("isDetailField", res?.item?.isDetailsFilled);

        // if (res?.item?.isDetailsFilled === false) {
        //   window.location.href = '/sign-up';
        // }
        setLoading(false)
        if (res?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || res?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
        logout()
       
      
      }
      else{
        setLoading(false)
        console.log("error", res?.data)
      }
    }
    catch(error){
      setLoading(false)
      console.log(error);
    }
  }
  const fetchRenewDetails = async(user_id)=>{
    try{
      const res = await userRenewDetails(user_id)
      // console.log("ress", res?.data)
      setRenewDetailsData(res?.data)
      setRenderChild(true)
      setLoading(false)
    }
    catch(error){
      setLoading(false)
      console.log(error);
    }
  }
  const fetchUserPremiumDetails = async (id) => {
    try{

    
    // const id = walletDetails?.user_id //JSON.parse(localStorage.getItem(constants.profileLite))?.item?.user_id
    let res = await isUserPremium(id)
    // console.log("res", res)
    if (res?.success) {
      setPremiumUser(res?.data[0]?.param)
      localStorage.setItem("premiumUser", res?.data[0]?.param?.isPremium)
      localStorage.setItem("noOfContest", res?.data[0]?.param?.nonPremiumUser?.noOfFreeContest)
      localStorage.setItem("noOfTournament", res?.data[0]?.param?.nonPremiumUser?.noOfFreeTournament)
      if(res?.data[0]?.param?.isPremium){
        fetchRenewDetails(id)
       
      }
      else{
        setRenderChild(true)
        setLoading(false)
      }
      // localStorage.setItem("premiumUser", false) 
    }
  }
  catch(error){
    console.log(error);
    setLoading(false)
  }
  }
  useEffect(()=>{
    console.log(localStorage.getItem("gamerjiToken") != null ||  localStorage.getItem("gamerjiToken") != undefined)
    if ( localStorage.getItem("gamerjiToken") != null ||  localStorage.getItem("gamerjiToken") != undefined)
      fetchLiteProfile()
    else
      setRenderChild(true)
  },[])
  if(loading) return(
    <div
    className="inner-wrap custom_scroll_leader"
    style={{ paddingTop: "8px" }}
  >
    <Loader />
    </div>
    )
  return (
    <ProfileContext.Provider
      value={{
        // onClickInfo,
        // topLinkClick,
        // fetchData,
        // allData,
        // loading,
        // collegeList,
        // levelProgressValue,
        // gameStatsList,
        // showInfo,
        // HandleTabClick,
        // activeTab,
        // options
        walletDetails,
        renewDetailsData,
        premiumUserDetails,
        updateWalletDetails
      }}
    >
      {/* {console.log("rendjnd")} */}
      {renderChild &&  children }
      {/* children */}
    </ProfileContext.Provider>
  );
};
