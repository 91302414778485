import React from "react";

import "react-intl-tel-input/dist/main.css";
import { woeLiveStreamingsList, woeUsersList, woeBlogsList } from "../apis";
import url from "../constants/url";
import Loader from "../component/loader";
import ReactPlayer from "react-player";

import { handleTextAnimation } from "../component/textAnimation";

import WOEChannles from "./WOEChannels";
import WOEAllFeaturedVideos from "./WOEAllFeaturedVideos";
import WOEAllTopProfiles from "./WOEAllTopProfiles";
import WOEAllEsportsNews from "./WOEAllEsportsNews";
import WOEIndividualFeaturedVideos from "./WOEIndividualFeaturedVideos";
import WOEIndividualEsportsNews from "./WOEIndividualEsportsNews";

import "./world-of-esports.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import WoeTopProfilesAvatar from '../assets/images/woe_top_profiles_avatar.jpg'
import { withTranslation } from "react-i18next";

class WorldOfEsportNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      WOEActiveScreen: 0,
      WOEAllFeaturedVideosActiveScreen: 0,

      limit: 2,
      featured_video: [{ name: "", url: "" }],
      popular_videos: [
        { name: "", url: "" },
        { name: "", url: "" },
        { name: "", url: "" },
      ],

      top_profiles: [
        // { gamerjiName: "", followers: "" },
        // { gamerjiName: "", followers: "" },
        // { gamerjiName: "", followers: "" },
      ],

      esports_news: [],

      individual_popular_videos: { url: "", name: "" },
      individual_esports_news: [],

      tag_featured_videos_loader: true,
      tag_top_profiles_loader: true,
      tag_esports_news_loader: true,
    };

    this.onChangeWOEActiveScreen = this.onChangeWOEActiveScreen.bind(this);
    this.onChangeWOEAllFeaturedVideosActiveScreen =
      this.onChangeWOEAllFeaturedVideosActiveScreen.bind(this);
  }

  componentDidMount() {
    this.woeLiveStreamingsListApiCall(true);
    this.woeLiveStreamingsListApiCall(false);
    this.woeUsersListApiCall();
    this.woeBlogsListApiCall();
  }

  componentDidUpdate() {
    handleTextAnimation();
  }

  onChangeWOEActiveScreen(active) {
    this.setState({ WOEActiveScreen: active });
  }

  onChangeWOEAllFeaturedVideosActiveScreen(active) {
    this.setState({ WOEAllFeaturedVideosActiveScreen: active });
  }

  async woeLiveStreamingsListApiCall(isLive) {
    this.setState({ tag_featured_videos_loader: true });
    let data = {
      skip: 0,

      limit: isLive ? 1 : this.state.limit,
      filter: {
        isLive: isLive, // isLive
      },
    };

    let response = await woeLiveStreamingsList(data);
    if (response !== undefined) {
      if (response?.list && response?.list?.length > 0) {
        response.list.forEach((value, i) => {
          if (value?.url?.includes("embed")) {
          } else if (value?.url?.includes("watch")) {
            let str = value.url.split("=");
            response.list[i]["url"] =
              "https://www.youtube.com/embed/" + str[str.length - 1];
          } else if (value?.url?.includes("youtu.be")) {
            let str = value.url.split("/");
            response.list[i]["url"] =
              "https://www.youtube.com/embed/" + str[str.length - 1];
          }
        });
        // if (isLive) this.setState({ featured_video: response.list });
        // else 
        this.setState({ popular_videos: response.list });
      }
    }
    this.setState({ tag_featured_videos_loader: false });
  }

  async woeUsersListApiCall() {
    //Added by kudeep just for testing  
    // let data2 = JSON.stringify({
    //   "skip": 0,
    //   "limit": 3,
    //   "type": [
    //     "appUser"
    //   ],
    //   "sort": "desc",
    //   "sortBy": "followers",
    //   "action": "topProfile"
    // });

    // let config = {
    //   method: 'post',
    //   maxBodyLength: Infinity,
    //   url: `${url.apiUrl}users/list/1687329578698`,
    //   headers: {
    //     'x-access-token': 'Lonf+7igc2vPLURLiz3z1Ol96M3UX4NYqCuY4iwJS/DvKt9XolC+cNzeGvxytXzol2RCPGJZxAFXzJPsBfB9zg==',
    //     'Content-Type': 'application/json'
    //   },
    //   data: data2,

    // };

    // axios.request(config)
    //   .then((response) => {
    //     console.log("response.data from axios", response.data);
    //     if (response.data !== undefined) {
    //       if ("list" in response.data) {
    //         this.setState({ top_profiles: response.data.list });
    //       }
    //     } else {
    //       this.setState({ tag_top_profiles_loader: true });
    //     }
    //     this.setState({ tag_top_profiles_loader: false });
    //   })
    //   .catch((error) => {
    //     console.log("error from axios", error);
    //   });
    //Added by kudeep just for testing  
    this.setState({ tag_top_profiles_loader: true });
    let data = {
      skip: 0,
      limit: 2,
      type: ["appUser"],
      sort: "desc",
      sortBy: "followers",
      action: "topProfile",
    };

    let response = await woeUsersList(data);
    if (response !== undefined) {
      if ("list" in response) {
        this.setState({ top_profiles: response.list });
      }
    } else {
      this.setState({ tag_top_profiles_loader: true });
    }
    this.setState({ tag_top_profiles_loader: false });
  }

  async woeBlogsListApiCall() {
    this.setState({ tag_esports_news_loader: true });
    let data = {
      skip: 0,
      limit: this.state.limit,
      sort: "desc",
      sortBy: "createdAt",
    };

    let response = await woeBlogsList(data);

    if (response !== undefined) {
      if ("list" in response) {
        this.setState({ esports_news: response.list });
      }
    }
    this.setState({ tag_esports_news_loader: false });
  }

  createWorldOfEsports() {
    const { t } = this.props;

    return (
      <div
        className="contest-list contest-tournament-list"
        id="tournament_main"
      >
        <div className="inner-wrap">
          <div className="header">
            <div className="back-btn"></div>
            <h2>{t('header.world_of_esports')}</h2>
            <div className="wallet-btn"></div>
          </div>
          <div className="body bottom-btn-body" style={{ padding: "0" }}>
            <div className="woe_main_col position ">
              {/* adding new */}

              <div className="mainSection">
                <div className="yellow-section"></div>
                <div className="white-section"></div>
              </div>

              <div className="section-worldofesports">
                <div className="title-section">
                  <div className="margin-auto"></div>
                  <div className="Title white-text">{t('woe.featured_videos')}</div>
                  <div className="margin-auto"></div>
                </div>
                <div className="Card-container">
                  <ReactPlayer
                    controls
                    url={this.state.popular_videos?.[0]?.url}
                    height="100%"
                    width="100%"
                    className="react-player "
                  />
                </div>
                {/* title popular videos */}
                <div className="title-section">
                  <div className="margin-auto"></div>
                  <div className="Title">{t('woe.popular_videos')}</div>
                  <div
                    className="section-links"
                    onClick={(e) => this.onChangeWOEActiveScreen(1)}
                  >
                    {t('woe.view_all')}
                  </div>
                </div>
                <div className="card-section">
                  {this.state.popular_videos.map((card) => (
                    <div className="cards-container">
                      <ReactPlayer
                        controls
                        url={card?.url}
                        height="80%"
                        width="100%"
                        className="react-player"
                      />
                      {
                        card?.name?.length > 30 ?
                          <div className="marquee-div">
                            <div className="marquee-text">{card.name}</div>
                          </div> : card?.name
                      }

                    </div>
                  ))}
                </div>
                {/* top-profiles */}
                <div className="title-section">
                  <div className="margin-auto"></div>
                  <div className="Title">{t('woe.top_profiles')}</div>
                  <div
                    className="section-links"
                    onClick={(e) => this.onChangeWOEActiveScreen(2)}
                  // onClick={() => this.woeUsersListApiCall()}
                  >
                    {t('woe.view_all')}
                  </div>
                </div>

                <div className="woe_box_component_row" style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                  {
                    this.state.top_profiles.map((value, i) => {
                      return (
                        <div className="woe_top_profiles_box" key={i}
                          onClick={(e) => {
                            let allpage = [...JSON.parse(localStorage.getItem("currentPage"))];
                            var count = allpage.findIndex((d) => d === window.location.pathname);
                            count === -1 && allpage.push(window.location.pathname);
                            localStorage.setItem("currentPage", JSON.stringify(allpage));
                            window.location.href = `/user-profile/${value._id}`;
                          }}
                        >
                          <div className="woe_top_profiles_row">
                            <div className="woe_top_profiles_image" style={{ marginTop: '-30%' }}>
                              <img style={{ borderRadius: '50%' }} src={WoeTopProfilesAvatar} />
                            </div>
                          </div>
                          <div className="woe_top_profiles_player_gamerji_name">
                            {value.gamerjiName}
                          </div>
                          <div className="woe_top_profiles_player_followers">
                            {value.followers} {t('woe.followers')}
                          </div>
                          <button className="woe_top_profiles_follow_button">{value.isFollow ? `${t('woe.following')}` : `${t('woe.follow')}`}</button>
                        </div>
                      )
                    })
                  }
                </div>
                {/* top-profile cards */}
                {/* <div className="card-section">
                  {this.state.top_profiles.map((card) => (
                    <div className="container"> */}
                {/* <div className="avtarimage">
                        <img
                          src="https://res.cloudinary.com/dvbw8xujd/image/upload/v1652635707/w-1_c_mev7zg.jpg"
                          // height="50px"

                          className="border-radius2 card-img"
                          alt="avtar"
                        />
                      </div> */}
                {/* <div
                        className="card3-container"

                      >
                        <div className="card-text ">gg</div>
                        <div className="card-subText">gg</div>
                      </div>
                      <button className="card-button">Follow</button>
                    </div>
                  ))}
                </div> */}
                {/* esports news  */}
                <div className="title-section">
                  <div className="margin-auto"></div>
                  <div className="Title">{t('woe.esports_news')}</div>
                  <div
                    className="section-links"
                    onClick={(e) => this.onChangeWOEActiveScreen(3)}
                  >
                    {t('woe.view_all')}
                  </div>
                </div>
                <div className="card-section">
                  {this.state.esports_news.map((card) => (
                    <div
                      className="card2Container"
                      key={card._id}
                      onClick={(e) => {
                        this.setState(
                          {
                            individual_esports_news: card,
                          },
                          () => {
                            this.setState({ WOEActiveScreen: 4 });
                          }
                        );
                      }}
                    >
                      <div className="card2image">
                        <img
                          src={url.imageUrl + card.img.default}
                          className="border-radius card-img"
                        />
                      </div>

                      {
                        card?.slug?.length > 20 ?
                          <div className="marquee-div">
                            <div className="marquee-text">{card.slug}</div>
                          </div> : card?.slug
                      }
                      <div className="card2text">{card.title}</div>
                    </div>
                  ))}
                </div>

                {/* end */}
              </div>
            </div>
          </div>
          {/* <div className="bottom_btn">

</div> */}
        </div>

        {/* {showAllblogs && (
          <WOEAllEsportsNews
          //   individual_esports_news={this.state.individual_esports_news}
          //   onChangeWOEActiveScreen={this.onChangeWOEActiveScreen}
          />
      ) */}
        {/* } */}
      </div>
    );
  }

  handleWorldOfEsportsScreens(active) {
    switch (active) {
      case 0:
        return this.createWorldOfEsports();

      case 1:
        return (
          <WOEAllFeaturedVideos
            WOEActiveScreen={this.state.WOEActiveScreen}
            onChangeWOEActiveScreen={this.onChangeWOEActiveScreen}
          />
        );

      case 2:
        return (
          <WOEAllTopProfiles
            WOEActiveScreen={this.state.WOEActiveScreen}
            onChangeWOEActiveScreen={this.onChangeWOEActiveScreen}
          />
        );

      case 3:
        return (
          <WOEAllEsportsNews
            WOEActiveScreen={this.state.WOEActiveScreen}
            onChangeWOEActiveScreen={this.onChangeWOEActiveScreen}
          />
        );

      case 4:
        return (
          <WOEIndividualEsportsNews
            individual_esports_news={this.state.individual_esports_news}
            onChangeWOEActiveScreen={this.onChangeWOEActiveScreen}
          />
        );

      case 5:
        return (
          <WOEChannles
            WOEActiveScreen={this.state.WOEActiveScreen}
            onChangeWOEActiveScreen={this.onChangeWOEActiveScreen}
          />
        );

      default:
        return <div></div>;
    }
  }

  render() {
    return (
      <div>
        {this.state.tag_featured_videos_loader ||
          this.state.tag_top_profiles_loader ||
          this.state.tag_esports_news_loader ? (
          <Loader />
        ) : (
          ""
        )}
        {this.handleWorldOfEsportsScreens(this.state.WOEActiveScreen)}
        <div
          className="woe_individual_popular_video_component"
          hidden={this.state.WOEAllFeaturedVideosActiveScreen === 0}
        >
          <WOEIndividualFeaturedVideos
            individual_popular_videos={this.state.individual_popular_videos}
            onChangeWOEAllFeaturedVideosActiveScreen={
              this.onChangeWOEAllFeaturedVideosActiveScreen
            }
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(WorldOfEsportNew);

// export default WorldOfEsport;
