import React, { Fragment, useEffect, useState } from "react";
// import useRazorpay, { RazorpayOptions } from "react-razorpay";
import getSymbolFromCurrency from "currency-symbol-map";
// import currencyToSymbolMap from "currency-symbol-map/map";

import { useNavigate } from "react-router-dom";
import {
  getCoinStoreList,
  getAllAvatarList,
  getAllRewardCategoryList,
  getAllRewardProductMyList,
  // requestCreateCoinStoreTransactions,
  // requestCreateCFCoinStoreTransactions,
  getApplyReward,
  getBuyAvatar,
  // getUserProfile,
  // getGenrateSignature,
  getUserProfileLite,
  createXsollaTransaction,
  xsollaList,
  createAirPayTransaction,
  verifyAirPayTransaction,
  payUHash
} from "../apis";
import { routehelp } from "../config/routehelp";
// import Previous from "../assets/images/previous.svg";
// import Wallet from "../assets/images/wallet.svg";
import url from "../constants/url";
// import Dollar from "../assets/images/upload-card.svg";
import Coin from "../assets/images/coin.png";
import Diamond from "../assets/images/BLUE_Diamond.png";
import myRecentTransactionImage from "../assets/images/ic_transactions.png";
// import Rupee from "../assets/images/rupee.svg";
import { Link, useLocation } from "react-router-dom";
// import { Button } from "react-bootstrap";
import Cancel from "../assets/images/round_not_qualified.png";
import Close from "../assets/images/close.svg";
import {
  getConvertionDateTime,
  checkDobNstateConditions,
} from "../component/common";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessPayment from "../assets/images/success-payment.png";
import PendingPayment from "../assets/images/pending-payment.png";
import FailPayment from "../assets/images/fail-payment.png";
import PurchasePackIcon from "../assets/images/purchase-pack-icon.png";
// import AccountIcon from "../assets/images/account_icon.png";
// import sha256 from "crypto-js/sha256";
import helperFunc from "../helperFuncs";
// import HmacSHA256 from "crypto-js/hmac-sha256";
// import Base64 from "crypto-js/enc-base64";
// import { loadStripe } from "@stripe/stripe-js";
// import { useStripe } from "@stripe/react-stripe-js";
import Loader from "../component/loader";
// import { constants } from "../constants/constants";
import DobNStates from "./dobnstate";
import Advertisement from "../component/advertisement";
import EarnCoins from "./EarnCoins";
import QRCode from "react-qr-code";
import { useTranslation, Trans } from "react-i18next";
import Cookies from 'js-cookie'
import { useProfile } from "../component/ProfileComponent";
import PayUForm from "../component/PayU/PayUForm";

// var stripeAPIKey = helperFunc.getStripeAPIKey();
// const stripePromise = loadStripe(stripeAPIKey);

function CoinStore(props) {
  // DobNState Screen Name
  const dobNstateScreenNameBuyItem = "coin-store-buy-item";
  const dobNstateScreenNameRedeemItem = "coin-store-redeem-item";
  const { t } = useTranslation()

  // const Razorpay = useRazorpay();
  const navigate = useNavigate();
  const [allCoin1, setAllCoin1] = useState(null);
  const [allCoin2, setAllCoin2] = useState(null);
  const [allCoin3, setAllCoin3] = useState(null);
  const [allReward1, setAllReward1] = useState(null);
  const [allReward2, setAllReward2] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [activeCoinStoreTab, setActiveCoinStoreTab] = useState(0);
  const [activeReward, setActiveReward] = useState(0);
  // const [userProfile, setUserProfile] = useState(null);
  const [walletData, setWalletData] = useState(null);
  const [redeemData, setRedeemData] = useState({});

  const [showRedeem, setShowRedeem] = useState(false);

  // const [infoTitle, setInfoTitle] = useState("");
  // const [infoDesc, setInfoDesc] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const [showInfo, setShowInfo] = useState(false);
  const [showBundlesInfo, setShowBundlesInfo] = useState(false);
  const [avatarData, setAvatarData] = useState({});
  const [bundlesData, setBundlesData] = useState({});
  const [cfOrderData, setCFOrderData] = useState({});

  const [tagShowDobNstate, setTagShowDobNstate] = useState(false);
  const [buyItemData, setBuyItemData] = useState([]);
  const [token, setToken] = useState("");
  const [tagShowHowToRedeemPopup, setTagShowHowToRedeemPopup] = useState([]);
  // const [currency, setCurrency] = useState("");
  const [isQrCode, setIsQrCode] = useState(false);
  const [transactionUniqueID, setTransactionUniqueID] = useState();
  const [myRewardSkip, setMyRewardSkip] = useState(1);
  const [myRewardTotalRecord, setMyRewardTotalRecord] = useState(0);
  const myRewardLimit = 6;
  // const [transactionData, setTransactionData] = useState(null);
  // const [timer, setTimer] = useState(5 * 60 * 1000);
  const currentLang = Cookies.get('i18next') || 'EN'

  const param = useLocation()
  var userCountry = helperFunc.getUserCountry();
  // console.log(JSON.parse(localStorage.getItem("profileLite"))?.item?.country)
  let isDefault = !(JSON.parse(localStorage.getItem("profileLite"))?.item?.country?._id == "611e04284ac17121fd8b1a54");
  const {walletDetails, updateWalletDetails } = useProfile()  
  const [showPayuForm, setShowPayuForm] = useState(false)
  const [payUData, setPayuData] = useState({})
  // useEffect(() => {
  //   let returnUrl = Cookies.get("returnUrl");
  //   let returnUrlLocal = localStorage.getItem("returnUrl");

  //   if((returnUrl != undefined &&
  //   returnUrl != "undefined" &&
  //   returnUrl != null &&
  //   returnUrl != "null" &&
  //   returnUrl != "") || (returnUrlLocal != undefined &&
  //     returnUrlLocal != "undefined" &&
  //     returnUrlLocal != null &&
  //     returnUrlLocal != "null" &&
  //     returnUrlLocal != ""))
  //   {
  //     if((returnUrl != undefined &&
  //       returnUrl != "undefined" &&
  //       returnUrl != null &&
  //       returnUrl != "null" &&
  //       returnUrl != "")){
  //         localStorage.setItem("returnUrl", returnUrl);
  //       }
  //     setActiveTab(1);
  //   }
  // }, [])

  function removeParamsFromUrl(paramsToRemove) {
    // Get the current URL
    let url = new URL(window.location.href);

    // Get URLSearchParams object
    let params = url.searchParams;

    // Remove unwanted parameters
    paramsToRemove.forEach(param => params.delete(param));

    // Get the updated query string
    let updatedQueryString = params.toString();

    // Create a new URL with the updated query string
    let newUrl = url.origin + url.pathname + (updatedQueryString ? '?' + updatedQueryString : '');

    // Replace the current URL without triggering a page refresh
    window.history.replaceState(null, '', newUrl);
  }

  useEffect(() => {
    // Get the query parameters from the current URL
    const urlSearchParams = new URLSearchParams(window.location.search);

    // Access specific query parameters by name
    const returnUrl = urlSearchParams.get("returnUrl");

    if (
      returnUrl != undefined &&
      returnUrl != "undefined" &&
      returnUrl != null &&
      returnUrl != "null" &&
      returnUrl != ""
    ) {
      localStorage.setItem("returnUrl", returnUrl);
      setActiveTab(1);
      removeParamsFromUrl(["returnUrl"]);
    }
  }, [])

  // useEffect(() => {
  //   const clientSecretFromURL = new URLSearchParams(window.location.search).get(
  //     "payment_intent_client_secret"
  //   );
  //   const coinStoreIdFromURL = new URLSearchParams(window.location.search).get(
  //     "coinStore"
  //   );

  //   if (clientSecretFromURL) {
  //     createStripeTransactionWeb(coinStoreIdFromURL, clientSecretFromURL);
  //   }
  // }, []);

  useEffect(() => {
    if (param?.state){
      setActiveTab(1)

      setActiveCoinStoreTab(0)
      if(isDefault)
        virtualItems(1)
    }
    else getRewardList();

  }, []);
  useEffect(() => {
    var count;
    // var count1;

    if (isQrCode) {
      count = setInterval(() => verifyAirpay(), 15000);
      // setInterval(() => setTimer(timer - 1), 1000);
      setTimeout(() => {
        clearInterval(count)
        // clearInterval(count1)
        setIsQrCode(false);
      }, 5 * 60 * 1000)
    }


    return () => clearInterval(count);
  }, [isQrCode]);

  const verifyAirpay = async () => {
    let payload = {
      transactionUniqueID: transactionUniqueID
    };

    let data = await verifyAirPayTransaction(payload);
    if (data?.status !== "INITIATED") {
      navigate('/airpay-status', { state: data })
      // setTransactionData(data.data)
    } else {
    }
  }

  // const createStripeTransactionWeb = async (
  //   coinStoreIdFromURL,
  //   clientSecretFromURL
  // ) => {
  //   let res = await createStripeTransactionWeb({
  //     coinStore: coinStoreIdFromURL,
  //     clientSecret: clientSecretFromURL,
  //   });
  //   if (res) {
  //     // console.log("createStripeTransactionWeb==>res?.item===>", res?.item);
  //     //setClientSecret(res?.item?.clientSecret);
  //   }
  // };

  useEffect(() => {
    localStorage.setItem("platform", "WebApp");
    // fetchData();
    // if(param?.state === 1){
    //   setTimeout(() => {
    //     virtualItems(1);
    //   }, 1000)
    //   // fetchProfileData();
    //   return;
    // }
    // getRewardList();
    // fetchProfileData();
  }, []);

  const getTotalCoin = (wallet) => {
    let index = wallet?.findIndex((obj) => obj.currencyData?.code == "coin");

    if (index > -1) {
      return wallet[index].winningAmount ? Math.round(wallet[index].winningAmount) : 0;
    }
    return 0;
  };
  const getTotalDiamond = (wallet) => {
    // console.log(wallet, 'wallet')
    let index = wallet.findIndex((obj) => obj.currencyData?.code == "diamond");

    if (index > -1) {
      return wallet[index].winningAmount ? Math.round(wallet[index].winningAmount) : 0;
    }
    return 0;
  };

  const fetchProfileData = async () => {
    // setShowLoader(true)
    // // let res = await getUserProfileLite();
    // if (res) {
    //   // console.log(res)
    //   setWalletData(res?.item?.wallet)
    //   // setUserProfile(res);
    //   // console.log("res==>", res);
    //   // localStorage.setItem("currentCoin", getTotalCoin(res?.item?.wallet));
    //   // localStorage.setItem(
    //   //   "currentDiamond",
    //   //   getTotalDiamond(res?.item?.wallet)
    //   // );
    //   setShowLoader(false)
    // }
    // else {
    //   //console.log("errror")
    // }
  };

  const getRewardList = async () => {
    try {
      setShowLoader(true);
      let payload = { skip: 0, limit: 100, sort: "asc", sortBy: "order" };
      let response = await getAllRewardCategoryList(payload);
      if (response) {
        setAllReward1(response?.list);
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
    }
  }

  const fetchData = async () => {
    let one = {
      filter: { coinType: "1" },
      skip: 0,
      limit: 10,
      sort: "asc",
      sortBy: "amount",
    };
    let res = await getCoinStoreList(one);
    if (res) {
      setAllCoin1(res?.list);
    }

  };
  const fetchData2 = async () => {
    let two = {
      filter: { coinType: "2" },
      skip: 0,
      limit: 100,
      sort: "asc",
      sortBy: "amount",
    };
    let res1 = await getCoinStoreList(two);
    if (res1) {
      setAllCoin2(res1?.list);
    }

  };

  const getMyRewardList = async (skip) => {
    let five = { skip: skip, limit: myRewardLimit, sort: "asc", sortBy: "createdAt" };
    let res4 = await getAllRewardProductMyList(five);
    if (res4) {
      // console.log("setAllReward2: ", res4?.list);
      let temp = [];
      res4?.list?.forEach(() => {
        temp.push(false);
      });
      setAllReward2([...allReward2, ...res4?.list]);
      setMyRewardTotalRecord(res4?.count);
      setTagShowHowToRedeemPopup(temp);
      setShowLoader(false);
    }
  };

  const getLoadMore = async (type) => {
    setShowLoader(true);
    setMyRewardSkip(myRewardSkip + 1)
    getMyRewardList(myRewardSkip + 1);
  };

  const createPayuHash = async (item)=>{
    // console.log(item)
    try{
      let  payload = {
        key: process.env.REACT_APP_KEY,
        amount:item?.amount,
        productId: item?._id,
        email: walletDetails?.email || JSON.parse(localStorage.getItem('profileLite'))?.item?.email,
        phone: walletDetails?.phone || JSON.parse(localStorage.getItem('profileLite'))?.item?.phone,
        productInfo: process.env.REACT_APP_COIN_PRODUCT,
        surl: process.env.REACT_APP_BASE_URL + 'api/webapp/payu/payment-status',
        furl: process.env.REACT_APP_BASE_URL + 'api/webapp/payu/payment-status',
        curl: process.env.REACT_APP_BASE_URL + 'api/webapp/payu/payment-status',
        userId: walletDetails?._id,
        description:item?.coins,
      
        platform:"webapp"

      };
      // console.log(payload)
      let res = await payUHash(payload);
      // console.log(res)
      if(res?.success){
        payload = {...payload, hash: res?.hash,txnid: res?.transactionID }
        // console.log(payload)
      setPayuData(prev=> payload)
      setShowPayuForm(true)
      }
    }catch(error){
      console.log(error)
    }
  }

  const createAirpayTransaction = async (item) => {
    setShowLoader(true);
    // let countryObj = JSON.parse(localStorage.getItem("userCountryObj"));
    let payload = {
      coinStore: item._id,
      isMobile: false
    };

    let data = await createAirPayTransaction(payload);
    if (data?.success) {
      setToken(data?.data?.token);
      setTransactionUniqueID(data?.data?.transactionUniqueID);
      setIsQrCode(true);
      setShowLoader(false);
      // let url = data?.data?.token;
      // window.location.href = url;
    } else {
      setShowLoader(false);
    }
  }

  const moveToPaymentOptionScreen = async (item) => {
    //  const userProfileObj = JSON.parse(userProfile);
    // const userProfileObj = userProfile;

    // console.log("userProfile.rzpKey==>", userProfile);
    // debugger
    //console.log(
    //   "userProfile.razorPayEnabled==>",
    //   userProfileObj.razorPayEnabled
    // );

    window.location.href = "/payment-gateway-web/" + item._id;

    // console.log(item._id);
    // if(item._id)
    //   window.location.href = "/response/" + item._id;

    // if (userProfile?.country?.code === "IN") {
    //   // window.location.href = "/payment-gateway/" + item._id;
    //   if (userProfileObj?.razorPayEnabled === false) {
    //     //  alert(userProfileObj?.razorPayEnabled);

    //     let payload = {
    //       amount: item.amount,
    //       coinStore: item._id,
    //       paymentType: 17,
    //     };
    //     let createCFCoinStoreTransactionsResponse =
    //       await requestCreateCFCoinStoreTransactions(payload);

    //     if (
    //       createCFCoinStoreTransactionsResponse &&
    //       createCFCoinStoreTransactionsResponse.item
    //     )
    //       cashfreePayment(
    //         createCFCoinStoreTransactionsResponse.item,

    //       );

    //     // window.location.href = "/payment-options";
    //   } else {

    //     let payload = {
    //       amount: item.amount,
    //       coinStore: item._id,
    //       paymentType: 17,
    //     };
    //     let createCoinStoreTransactionsResponse =
    //       await requestCreateCoinStoreTransactions(payload);

    //     if (
    //       createCoinStoreTransactionsResponse &&
    //       createCoinStoreTransactionsResponse.item
    //     )
    //       razorpayPayment(
    //         createCoinStoreTransactionsResponse.item.order,
    //         userProfileObj.rzpKey
    //       );
    //   }

    // } else {
    //   window.location.href = "/payment-gateway-web/" + item._id;
    //  // window.location.href = "/payment-options/" + item._id;
    // }
  };

  // const cashfreePayment = async (order) => {
  //   var cfdatakeysArr = [
  //     "appId",
  //     "orderId",
  //     "orderAmount",
  //     "orderCurrency",
  //     "orderNote",
  //     "customerName",
  //     "customerPhone",
  //     "customerEmail",
  //     "returnUrl",
  //     "notifyUrl",
  //   ];
  //   var cfdataArr = [];
  //   cfdataArr["appId"] = "58874f6db6934ba819cab399247885";
  //   cfdataArr["orderId"] = order?.transactionUniqueID;
  //   cfdataArr["orderAmount"] = order?.amount;
  //   cfdataArr["orderCurrency"] = "INR";
  //   cfdataArr["orderNote"] = "Buy Coin";
  //   cfdataArr["customerName"] = userProfile?.item?.gamerjiName;
  //   cfdataArr["customerPhone"] = userProfile?.item?.phone;
  //   cfdataArr["customerEmail"] = userProfile?.item?.email;
  //   cfdataArr["returnUrl"] = url.publicUrl + "return-payment";
  //   cfdataArr["notifyUrl"] = url.publicUrl + "verify-payment";

  //   var signature = await genrateSignature(cfdatakeysArr, cfdataArr);
  //   var cfdata = {
  //     appId: "58874f6db6934ba819cab399247885",
  //     orderId: order?.transactionUniqueID,
  //     orderAmount: order?.amount,
  //     orderCurrency: "INR",
  //     orderNote: "Buy Coin",
  //     customerName: userProfile?.item?.gamerjiName,
  //     customerPhone: userProfile?.item?.phone,
  //     customerEmail: userProfile?.item?.email,
  //     returnUrl: url.publicUrl + "return-payment",
  //     notifyUrl: url.publicUrl + "verify-payment",
  //     signature: signature,
  //   };
  //   setCFOrderData(cfdata);
  //   document.getElementById("redirectForm").submit();
  // };

  // const razorpayPayment = async (order, rzpKey) => {
  //   // console.log("userProfile.item==>", userProfile);
  //   //  const userProfileObj = JSON.parse(userProfile);
  //   const userProfileObj = userProfile;

  //   // console.log("userProfile.item==>", userProfileObj.item);

  //   // window.location.href = "/payment-options";

  //   const options = {
  //     description: "Credits towards service",
  //     image: "https://www.gamerji.tech/leagueops/media/logos/256x256.png",
  //     currency: "INR",

  //     key: rzpKey,
  //     amount: "5000",
  //     //  amount: "order.amount",
  //     order_id: order.id,

  //     name: "Gamerji E-sports Private Limited",
  //     handler: function (response) {
  //       // console.log("response=>", response);
  //       // alert(response.razorpay_payment_id);
  //       // alert(response.razorpay_order_id);
  //       // alert(response.razorpay_signature);
  //       toast.success(t('success_Payment_successfull'), {
  //         position: "top-left",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     },
  //     prefill: {
  //       email: userProfileObj.item.email || "",
  //       contact: userProfileObj.item.phone,
  //       name: userProfileObj.item.gamerjiName || "",
  //     },
  //     notes: {
  //       address: "Razorpay Corporate Office",
  //     },
  //     theme: {
  //       color: "#070B28",
  //     },
  //   };

  //   // const rzp1 = new Razorpay(options);

  //   // rzp1.on("payment.failed", function (response) {
  //   //   // alert(response.error.code);
  //   //   // alert(response.error.description);
  //   //   // alert(response.error.source);
  //   //   // alert(response.error.step);
  //   //   // alert(response.error.reason);
  //   //   // alert(response.error.metadata.order_id);
  //   //   // alert(response.error.metadata.payment_id);
  //   //   toast.error(response.error.description, {
  //   //     position: "top-left",
  //   //     autoClose: 5000,
  //   //     hideProgressBar: false,
  //   //     closeOnClick: true,
  //   //     pauseOnHover: true,
  //   //     draggable: true,
  //   //     progress: undefined,
  //   //   });
  //   // });

  //   // rzp1.open();
  // };

  // const goback = () => {
  //   if (userCountry?.code === "IN") {
  //     window.location.href = "/account";
  //   } else {
  //     window.location.href = "/";
  //   }
  // };

  const onClickRedeem = (item) => {
    setShowRedeem(true);
    setRedeemData({
      id: item?._id,
      name: item?.name,
      description: item?.description,
      coinAmount: item?.coinAmount,
      img: item?.currency.img.default,
    });
  };

  useEffect(() => {
    if (localStorage?.getItem('setActiveReward') && (localStorage?.getItem('setActiveReward') == 1 || localStorage?.getItem('setActiveReward') == '1')) {
      setActiveTab(0);
      setActiveReward(1);
      getMyRewardList(1);
      localStorage?.removeItem('setActiveReward')
    }
  } , [activeReward])

  const OnClickRedeem = async () => {
    // console.log("redeem_data: ", redeemData);
    let data = { product: redeemData?.id };
    setShowRedeem(false);
    setShowLoader(true);
    let res = await getApplyReward(data);
    console.log("Redeem Reward: ", res.success && res.success === true);


    if (res) {
      if (res.success && res.success === true) {
        console.log("Redeem Reward: ", res.success && res.success === true);
        // fetchProfileData();
        updateWalletDetails()

        getMyRewardList(1);
        setActiveTab(0);
        setActiveReward(1);
        localStorage.setItem('setActiveReward', 1);
        toast.success(t('redeem_reward_successfully'), {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowLoader(false);
      } else {
        if (res?.data?.Error?.[0]?.msg) showError(res?.data?.Error?.[0]?.msg);
        else
          showError(res.data?.errors?.[0]?.msg);
        setShowLoader(false);
      }
    } else {
      showError(t('error_failed_to_load_reponse_data'));
      setShowLoader(false);
    }
  };

  const onClickInfo = (item) => {
    setShowInfo(true);
    setAvatarData(item);
  };

  const onClickBundlesInfo = (item) => {
    setShowBundlesInfo(true);
    setShowPayuForm(true)
    setBundlesData(item);
  };

  // const genrateSignature = async (itemKeys, signatureDataArr) => {
  //   var signatureData = "";

  //   itemKeys.sort();
  //   // console.log("itemKeys==>", itemKeys);
  //   // console.log("signatureDataArr==>", signatureDataArr);

  //   itemKeys.forEach((element, index) => {
  //     signatureData += element + signatureDataArr[element];
  //   });

  //   var hash = HmacSHA256(
  //     signatureData,
  //     "dd332db9b5c7010b7e5e834e5b5f2c9c9fda1145"
  //   );
  //   var hashInBase64 = Base64.stringify(hash);
  //   // console.log("hashInBase64==>", hashInBase64);

  //   return hashInBase64;
  //   // console.log("hashInBase64==>", hashInBase64);
  //   // if(signatureDataArr.length>0 && signatureDataArr.length===item.length){
  //   //   let res = await getGenrateSignature(signatureDataArr);
  //   //   console.log("test==>",res);
  //   //   if (res) {
  //   //     return res;
  //   //   }
  //   // }

  //   //return signatureData;
  //   // return Base64.stringify(sha256(signatureData, "dd332db9b5c7010b7e5e834e5b5f2c9c9fda1145"));
  // };

  const OnClickBuyAvatar = async (avatarId) => {
    // alert("Buy Avtar");

    let data = { avatar: avatarId };
    let res = await getBuyAvatar(data);
    // console.log("Buy Avatar: ", res);
    if (res) {
      setShowInfo(false);
      if (res.success === true) {
        let three = { skip: 0, limit: 100, sort: "asc", sortBy: "amount" };
        let res2 = await getAllAvatarList(three);
        if (res2) {
          setAllCoin3(res2?.list);
        }

        toast.success(t('avatar_buy_successfully'), {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(res.data?.errors?.[0]?.msg, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      // fetchProfileData();
      updateWalletDetails()
    }
  };

      
   
  const onClickBuyItem = async (item) => {
    localStorage.setItem("coinStoreId", item._id);
    setBuyItemData(item);
    // if(userProfile.item?.email === undefined || userProfile.item?.email === null ||
    //   userProfile.item?.email === "") {
    const res = await checkDobNstateConditions(dobNstateScreenNameBuyItem)
    // console.log(res)
    if (res) {
      // console.log("ckkjsfdnjd", dobNstateScreenNameBuyItem)
      setShowPayuForm(false)
      setTagShowDobNstate(true);
    } else {
      // createAirpayTransaction(item)
      // moveToPaymentOptionScreen(item);
      
      createPayuHash(item)
    }
  };

  const onClickBuyItem2 = async (bundlesData) => {
    localStorage.setItem("coinStoreId", bundlesData._id);
    setBuyItemData(bundlesData);
    // if(userProfile.item?.email === undefined || userProfile.item?.email === null ||
    //   userProfile.item?.email === "") {
    const res = await checkDobNstateConditions(dobNstateScreenNameBuyItem)
    // console.log(res)
    if (res) {
      //console.log("ckkjsfdnjd", dobNstateScreenNameBuyItem)
      setShowPayuForm(false)
      setShowBundlesInfo(false)
      setTagShowDobNstate(true);
    } else {
      // moveToPaymentOptionScreen(bundlesData);
      // createAirpayTransaction(bundlesData)
      createPayuHash(bundlesData)
    }
  };

  const onClickRedeemItem = async () => {
    // if(userProfile.item?.email === undefined || userProfile.item?.email === null ||
    //   userProfile.item?.email === "") {
    // console.log(checkDobNstateConditions(dobNstateScreenNameRedeemItem))
    const res = await checkDobNstateConditions(dobNstateScreenNameRedeemItem)
    // console.log(res)
    if (res) {
      //console.log("ckkjsfdnjd", dobNstateScreenNameBuyItem)

      setTagShowDobNstate(true);
    } else {
      OnClickRedeem();
    }
  };

  const submitDobNstateForBuyItem = async(tag) => {
    // setTagShowDobNstate(tag);
    // updateWalletDetails()  
    // // console.log(JSON.parse(localStorage.getItem("isDefault")))
    // if(JSON.parse(localStorage.getItem('countryId')) == "611e04284ac17121fd8b1a54")
    //   createPayuHash()
    // else{
    //   orderCreation()
    // }
    console.log(tag)
    // setTagShowDobNstate(false);

    if (tag) {

    } else {
      setTagShowDobNstate(false);
      // window.location.href = "/payment-gateway-web/" + paymentId;
      // updateWalletDetails()  
      if(JSON.parse(localStorage.getItem('countryId')) == "611e04284ac17121fd8b1a54")
        await createPayuHash(buyItemData)
      // else
      // orderCreation(buyItemData?.sku,
      //   buyItemData?.price?.amount,
      //   buyItemData?.description,
      //   1)
    }
    // if (!isDefault) {
    //   moveToPaymentOptionScreen(buyItemData);
    // }
  };

  const submitDobNstateForRedeemItem = (tag) => {
    setTagShowDobNstate(tag);
    OnClickRedeem();
  };

  const cancelDobNstate = () => {
    setTagShowDobNstate(false);
  };

  const createHowToRedeemPopup = (html_data, index) => {
    return (
      <div className="coin_store_htr_main_div">
        <ToastContainer />
        <div className="coin_store_htr_popup">
          <div className="coin_store_htr_header">
            <div className=""></div>
            <div className="coin_store_htr_header_text">{t('coin_store.how_to_redeem')}</div>
            <div className="coin_store_htr_cancel_icon">
              <img
                src={Cancel}
                onClick={() => {
                  let temp = [...tagShowHowToRedeemPopup];
                  temp[index] = false;
                  setTagShowHowToRedeemPopup(temp);
                }}
              />
            </div>
          </div>

          <div className="coin_store_htr_body">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="coin_store_htr_body_scrollable_component">
                <div
                  className="coin_store_htr_text"
                  dangerouslySetInnerHTML={{
                    __html: html_data?.redemptionInstructions,
                  }}
                ></div>
              </div>
            </div>
            {/* <Advertisement
              screen={
                localStorage.getItem('apk_screens_list') === null ?
                  { code: 'no_results' }
                  :
                  JSON.parse(localStorage.getItem('apk_screens_list')).joinViaInviteCodePopup
              }
              screen_div_class_name='.all_sub_screens_div_related_to_ads'
              screen_type='sub'
            /> */}
          </div>
        </div>
      </div>
    );
  };
  const QrCodePopUp = (index) => {
    return (
      <div className="coin_store_htr_main_div">
        <ToastContainer />
        <div className="coin_store_htr_popup">
          <div className="coin_store_htr_header">
            <div className=""></div>
            <div className="coin_store_htr_header_text">{t('coin_store.qr_code')}</div>
            <div className="coin_store_htr_cancel_icon">
              <img
                src={Cancel}
                onClick={() => {
                  let temp = [...tagShowHowToRedeemPopup];
                  temp[index] = false;
                  setTagShowHowToRedeemPopup(temp);
                  setIsQrCode(false)
                }}
              />
            </div>
          </div>
          <div className="coin_store_htr_body">
            <div className="all_sub_screens_div_related_to_ads">
              <div className="coin_store_htr_body_scrollable_component">
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={token}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showError = (error) => {
    toast.error(error, {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // get Catolog
  const [currencySymbol, setCurrencySymbol] = useState("INR");

  useEffect(() => {
    localStorage.setItem("platform", "WebApp");

    console.log(">>>", activeCoinStoreTab);
    // fetchData();
    // fetchProfileData();
    // console.log("isDefault", isDefault);
    if (isDefault) {
      setVirtualItem([]);
      // if (activeCoinStoreTab == 0)
      setAllCoin1([]);
      // if (activeCoinStoreTab == 1)
      setAllCoin2([]);
      // virtualItems();
    }
  }, [activeCoinStoreTab]);

  const [virtualItem, setVirtualItem] = useState([]);
  const [currencyImg, setCurrencyImg] = useState("");
  const virtualItems = async (group_id) => {
    setShowLoader(true);
    setVirtualItem([]);
    let countryObj = JSON.parse(localStorage.getItem("userCountryObj"));
    setCurrencyImg(countryObj?.flag?.default);
    const body = {
      group_id: group_id,
      limit: 10,
      skip: 0,
      country: countryObj?.code,
    };
    const res = await xsollaList(body);
    // console.log("res>>>>", res);
    if (res?.success) {
      setVirtualItem(res?.items);
      setCurrencySymbol(
        getSymbolFromCurrency(res?.items[0]?.price?.currency)
      );
      localStorage.setItem("currecySymbol", res?.items[0]?.price?.currency);
    }
    setShowLoader(false);
  };
  const getCoinAvatars = async () => {
    setShowLoader(true);
    let three = { skip: 0, limit: 100, sort: "asc", sortBy: "amount" };
    let res2 = await getAllAvatarList(three);
    if (res2) {
      setAllCoin3(res2?.list);
      setShowLoader(false);
    } else {
      setShowLoader(false);
    }

  };


  // xsolla order creation
  async function orderCreation(item_sku, amount, coin, type) {
    const res = await checkDobNstateConditions(dobNstateScreenNameBuyItem)
    if (res) {
      setTagShowDobNstate(true);
    } else {
      setShowLoader(true);
      let countryObj = JSON.parse(localStorage.getItem("userCountryObj"));
      let payload = {
        sku: item_sku,
        userCurrency: countryObj.code,
        // amount: parseInt(amount),
        amount: amount,
        type: type,
        isMobile: true
      };

      let data = await createXsollaTransaction(payload);
      if (data?.success) {
        let transactionUniqueID = data?.data?.transactionUniqueID;
        let body = {
          transactionUniqueID: transactionUniqueID,
          coin: coin,
          sku: item_sku,
          // amount: parseInt(amount),
          amount: amount,
          isPWA: true
        };
        localStorage.setItem("body", JSON.stringify(body));
        setToken(data?.data?.token);
        setShowLoader(false);
        // if (checkDobNstateConditions(dobNstateScreenNameBuyItem)) {
        //   setTagShowDobNstate(true);
        // } else {

        let url = `https://sandbox-secure.xsolla.com/paystation4/?token=${data?.data?.token}`;
        window.location.href = url;
        // }
      }
    }
  }
  // xsolla payment creation

  //<div className={`owl-theme ${isRTL ? 'rtl' : ''}`}>
  const isRTL = Cookies.get('i18next').toLowerCase() == 'ar' ? 'true' : 'false'
  const Owloptions = {

    rtl: isRTL, // Enable RTL mode
    items: 2,
    // Add other Owl Carousel options as needed
  }

  return (
    <Fragment>
      {/* {console.log("loader", showLoader)} */}
      
      <div
        className="reward_main reward_first_main custom_scroll mCustomScrollbar _mCS_1"
        id={
          showRedeem === true ||  showInfo === true || showBundlesInfo == true
            ? "popup_show_redeem"
            : ""
        }
      >
        <ToastContainer />
        {tagShowDobNstate === true ? (
          <DobNStates
            submitDobNstate={
              showRedeem === true
                ? submitDobNstateForRedeemItem
                : submitDobNstateForBuyItem
            }
            cancelDobNstate={cancelDobNstate}
            screen={
              showRedeem === true
                ? dobNstateScreenNameRedeemItem
                : dobNstateScreenNameBuyItem
            }
          />
        ) : (
          ""
        )}
        <div className="reward_title">
          <div className="row">
            <div className="back-btn col-2 text-center"></div>
            <h3
              className="col-8 text-center"
              style={{
                marginBottom: "20px",
                fontSize: "20px",
                fontWeight: "1000",
              }}
            >
              {t('coin_store.reward_store')}
            </h3>
            {/* <div className="wallet-btn col-2">
              {helperFunc.getUserCountry()?.code === "IN" ? (
                <a onClick={() => goback()} style={{ cursor: "pointer" }}>
                  <img src={AccountIcon} alt="" />
                </a>
              ) : (
                ""
              )}
            </div> */}
          </div>

          <div className="row rewards_tab">
            <div
              className={`col-4 d-flex justify-content-center p-0 ${activeTab === 0 ? "active" : ""
                }`}
              onClick={() => {
                setActiveTab(0)
                setActiveReward(0)
                getRewardList();
              }}
            >
              <p style={{ width: "100%", fontWeight: "600" }}>{t('coin_store.rewards')}</p>
            </div>
            <div
              className={`col-4 d-flex justify-content-center p-0 ${activeTab === 1 ? "active" : ""
                }`}
              onClick={() => {
                if (isDefault) {
                  virtualItems(1);
                } else {
                  fetchData();
                }
                setActiveCoinStoreTab(0)
                setActiveTab(1)
              }}
            >
              <p style={{ width: "100%", fontWeight: "600" }}>{t('coin_store.coin_store')}</p>
            </div>
            <div
              className={`col-4 d-flex justify-content-center p-0 ${activeTab === 2 ? "active" : ""
                }`}
              onClick={() => setActiveTab(2)}
            >
              <p style={{ width: "100%", fontWeight: "600" }}>{t('coin_store.earn_coins')}</p>
            </div>
          </div>

          <div className="row rewards_coin">
            <div
              className="mt-4 d-flex justify-content-center align-items-center rewards-diamond"

            >
              <div
                className="text-white d-flex align-items-center fontSize16"
                style={{ fontWeight: "600" }}
              >
                <img
                  src={Diamond}
                  className="coin_img_medium icon-24"
                  alt="diamond"
                />{" "}
                {/* {walletData ? getTotalDiamond(walletData) : 0} */}
                { walletDetails?.wallet?.filter((curr)=> curr?.currencyData?.code == process.env.REACT_APP_CURRENCY_CODE_OUT)?.[0]?.winningAmount || 0}
              </div>
            </div>
            <div
              className="mt-4 text-dark d-flex justify-content-center rewards-coin"

            >
              <div
                className="text-white d-flex align-items-center fontSize16"
                style={{ fontWeight: "600" }}
              >
                <img
                  src={Coin}
                  className="coin_img_medium icon-24"
                  alt="coin"
                />{" "}
                {/* {walletData ? getTotalCoin(walletData) : 0} */}
                {/* {console.log(walletDetails)} */}
                { walletDetails?.wallet?.filter((curr)=> curr?.currencyData?.code == process.env.REACT_APP_CURRENCY_CODE)?.[0]?.winningAmount || 0}
              </div>
            </div>
          </div>
        </div>
       
        <div className="reward_second_main" style={{ paddingBottom: "30px" }}>
        {showLoader ? <Loader /> : 
          activeTab === 1 ? (
            <div
              className="all_sub_screens_div_related_to_ads"
              style={{ overflowY: "inherit", overflowX: "inherit" }}
            >
              <div
                className="list-group bg-primary main-tab  coin_reward_tabs"
                id="list-tab"
                role="tablist"
              >
                <a
                  className={`list-group-item list-group-item-action ${activeTab === 1 && activeCoinStoreTab === 0
                    ? "active"
                    : "text-dark"
                    }`}
                  id="list-home-list"
                  data-toggle="list"
                  // href="#list-1"
                  onClick={() => {
                    setActiveCoinStoreTab(0);
                    if (isDefault) {
                      virtualItems(1);
                    } else {
                      fetchData2();
                    }
                  }}
                >
                  {t('coin_store.packs')}
                </a>
                <a
                  className={`list-group-item list-group-item-action ${activeTab === 1 && activeCoinStoreTab === 1
                    ? "active"
                    : "text-dark"
                    }`}
                  id="list-profile-list"
                  data-toggle="list"
                  // href="#list-2"
                  onClick={() => {
                    setActiveCoinStoreTab(1)
                    if (isDefault) {
                      virtualItems(2);
                    } else {
                      fetchData2();
                    }
                  }}
                >
                  {t('coin_store.bundles')}
                </a>
                <a
                  className={`list-group-item list-group-item-action ${activeTab === 1 && activeCoinStoreTab === 2
                    ? "active"
                    : "text-dark"
                    }`}
                  id="list-profile-list"
                  data-toggle="list"
                  // href="#list-3"
                  onClick={() => { getCoinAvatars(); setActiveCoinStoreTab(2); }}
                >
                  {t('coin_store.avatars')}
                </a>
              </div>
              <div className="tab-content h-100" id="nav-tabContent">

                {activeTab === 1 && activeCoinStoreTab === 0 && (
                  <div className="tab-pane h-100 active mx-4 d-flex flex-column justify-content-between">
                    <div className="row" style={{ marginTop: "10px" }}>
                      {virtualItem?.length > 0 ? (
                        virtualItem?.map((item, index) => {
                          return (
                            <div
                              className="col-4 packs_main text-center"
                              style={{ padding: "5px" }}
                              key={`allCoin1_${index}`}
                            >
                              <div className="packs_first">
                                <img src={PurchasePackIcon}  alt="coin"/>
                                <p
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.description}
                                  {/* 200 */}
                                </p>
                                <p>{t('coin_store.coins')}</p>
                              </div>
                              {/* {console.log(">>>", currencySymbol)} */}
                              <div
                                className="packs_second"
                                onClick={() =>
                                  orderCreation(
                                    item?.sku,
                                    item?.price?.amount,
                                    item?.description,
                                    1
                                  )
                                }
                              >
                                {t('coin_store.buy')} {currencySymbol}{" "}
                                {/* {parseInt(item?.price?.amount)} */}
                                {item?.price?.amount}
                              </div>
                            </div>
                          );
                        })
                      ) : allCoin1 !== null ? (
                        allCoin1?.map((item, index) => {
                          return (
                            <div
                              className="col-4 packs_main text-center"
                              style={{ padding: "5px" }}
                              key={`allCoin1_${index}`}
                            >
                              <div className="packs_first">
                                <img src={PurchasePackIcon} />
                                <p
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.coins}
                                </p>
                                <p> {t('coin_store.coins')}</p>
                              </div>
                              <div
                                className="packs_second"
                                onClick={() => onClickBuyItem(item)}
                              >
                                {t('coin_store.buy')} {item?.currency?.symbol} {item?.amount}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        // <Loader />
                        <div
                          className="d-flex justify-content-center"
                          style={{}}
                        >
                          <div
                            style={{
                              padding: "20px",
                              // color: "#F92C2C",
                              fontSize: "18px",
                              fontWeight: "1000",
                            }}
                          >
                            {t('notFound.no_records_found')}
                          </div>
                        </div>
                      )}
                      {/* {console.log("cfOrderData==>", cfOrderData)} */}
                      <form
                        id="redirectForm"
                        method="post"
                        action="https://www.cashfree.com/checkout/post/submit"
                      >
                        <input
                          type="hidden"
                          name="appId"
                          value={cfOrderData?.appId}
                        />
                        <input
                          type="hidden"
                          name="orderId"
                          value={cfOrderData?.orderId}
                        />
                        <input
                          type="hidden"
                          name="orderAmount"
                          value={cfOrderData?.orderAmount}
                        />
                        <input
                          type="hidden"
                          name="orderCurrency"
                          value={cfOrderData?.orderCurrency}
                        />
                        <input
                          type="hidden"
                          name="orderNote"
                          value={cfOrderData?.orderNote}
                        />
                        <input
                          type="hidden"
                          name="customerName"
                          value={cfOrderData?.customerName}
                        />
                        <input
                          type="hidden"
                          name="customerEmail"
                          value={cfOrderData?.customerEmail}
                        />
                        <input
                          type="hidden"
                          name="customerPhone"
                          value={cfOrderData?.customerPhone}
                        />

                        <input
                          type="hidden"
                          name="returnUrl"
                          value={cfOrderData?.returnUrl}
                        />
                        <input
                          type="hidden"
                          name="notifyUrl"
                          value={cfOrderData?.notifyUrl}
                        />
                        <input
                          type="hidden"
                          name="signature"
                          value={cfOrderData?.signature}
                        />

                        {/* <input type="submit" value="Pay"></input> */}
                      </form>
                      {/* <form  id="redirectForm" method="post" action="https://www.cashfree.com/checkout/post/submit">
    <input type="hidden" name="appId" value="58874f6db6934ba819cab399247885"/>
    <input type="hidden" name="orderId" value="order00001"/>
    <input type="hidden" name="orderAmount" value="100"/>
    <input type="hidden" name="orderCurrency" value="INR"/>
    <input type="hidden" name="orderNote" value="test"/>
    <input type="hidden" name="customerName" value="John Doe"/>
    <input type="hidden" name="customerEmail" value="Johndoe@test.com"/>
    <input type="hidden" name="customerPhone" value="9999999999"/>
    
    <input type="hidden" name="returnUrl" value="https://web.gamerji.com/coin-store"/>
    <input type="hidden" name="notifyUrl" value="https://web.gamerji.com/coin-store"/>
    <input type="hidden" name="signature" value="TLhlf0ghma4qSq1WmCZ5G7cD8XUKFkCaQeAQHWL3QAk="/>
    
    <input type="submit" value="Pay"></input>
    </form> */}
                    </div>
                    <div
                      className="d-flex w-100 justify-content-between align-items-center bg-primary"
                      style={{
                        padding: "10px 20px",
                        borderRadius: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        localStorage.setItem("myRecentTransactions", "coin");
                        // window.location.href = routehelp.myRecentTransactions;
                        navigate(routehelp.myRecentTransactions)
                      }}
                    >
                      <img src={myRecentTransactionImage} alt="transaction"/>
                      <p
                        className="m-0"
                        style={{ fontSize: "18px", fontWeight: "600" }}
                      >
                        {t('coin_store.my_recent_transactions')}
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.334"
                        height="18.014"
                        viewBox="0 0 21.334 18.014"
                      >
                        <g
                          id="ico"
                          transform="translate(21.334 18.013) rotate(-180)"
                        >
                          <path
                            id="union"
                            d="M8.321,17.721.479,9.879A1,1,0,0,1,.125,8.541,1.005,1.005,0,0,1,.411,8.2L8.321.293A1,1,0,0,1,9.735,1.706L3.417,8.025H20.334a1,1,0,1,1,0,2H3.454l6.281,6.281a1,1,0,0,1-1.414,1.415Z"
                            transform="translate(0 0)"
                            fill="#000"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                )}
                {activeTab === 1 && activeCoinStoreTab === 1 && (
                  <div className="tab-pane active mx-4">
                    <div className="row">
                      {virtualItem?.length > 0 ? (
                        virtualItem.map((item, index) => (
                          <div
                            className="col-6 bundles_main text-center my-3"
                            key={`allCoin2_${index}`}
                          >
                            <div className="bg-design"></div>
                            <div className="bundles_first">
                              <img src={item?.image_url} alt="avtar" />
                              <p
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                  lineHeight: "2ch",
                                }}
                              >
                                <img src={Coin} className="coin_img_avatar"  alt="coin" />
                                <span
                                  style={{ position: "relative", top: "2px" }}
                                >
                                  {" "}
                                  {item?.description}{" "}
                                </span>
                                <small>{t('coin_store.coins')}</small>
                              </p>
                              <small style={{ lineHeight: "2ch" }}>+</small>
                              <p style={{ lineHeight: "2ch" }}>{item?.name}</p>
                            </div>
                            {item?.isPurchase ? (
                              <div className="bundles_second">{t('coin_store.bought')}</div>
                            ) : (
                              <div
                                className="bundles_second"
                                onClick={() => {
                                  // onClickBundlesInfo(item)
                                  orderCreation(
                                    item?.sku,
                                    item?.price?.amount,
                                    item?.description,
                                    2
                                  );
                                }}
                              >
                                {t('coin_store.buy')} {currencySymbol}{" "}
                                {/* {JSON.parse(item?.price?.amount)} */}{" "}
                                {/* {parseInt(item?.price?.amount)} */}
                                {item?.price?.amount}
                              </div>
                            )}
                          </div>
                        ))
                      ) : allCoin2 !== null ? (
                        allCoin2?.map((item, index) => {
                          return (
                            <div
                              className="col-6 bundles_main text-center my-3"
                              key={`allCoin2_${index}`}
                            >
                              <div className="bg-design"></div>
                              <div className="bundles_first">
                                <img
                                  src={
                                    url.imageUrl + item?.avatar?.img?.default
                                  }
                                  alt="avatar"
                                />
                                <p
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    marginBottom: "0px",
                                    lineHeight: "2ch",
                                  }}
                                >
                                  <img src={Coin} className="coin_img_avatar"  alt="coin"/>
                                  <span
                                    style={{
                                      position: "relative",
                                      top: "2px",
                                    }}
                                  >
                                    {" "}
                                    {item?.coins}{" "}
                                  </span>
                                  <small>{t('coin_store.coins')}</small>
                                </p>
                                <small style={{ lineHeight: "2ch" }}>+</small>
                                <p style={{ lineHeight: "2ch" }}>
                                  {item?.avatar?.name}
                                </p>
                              </div>
                              {item.isPurchase ? (
                                <div className="bundles_second">{t('coin_store.bought')}</div>
                              ) : (
                                <div
                                  className="bundles_second"
                                  onClick={() => {onClickBundlesInfo(item);
                                    onClickBuyItem2(item)
                                  }}
                                >
                                  {t('coin_store.buy')} {item?.currency?.symbol}
                                  {item?.amount}
                                </div>
                              )}
                            </div>
                          );
                        })
                      ) : (
                        // <Loader />
                        <div
                          className="d-flex justify-content-center"
                          style={{}}
                        >
                          <div
                            style={{
                              padding: "20px",
                              // color: "#F92C2C",
                              fontSize: "18px",
                              fontWeight: "1000",
                            }}
                          >
                            {t('notFound.no_records_found')}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {activeTab === 1 && activeCoinStoreTab === 2 && (
                  <div className="tab-pane active mx-4">
                    <div className="avatars_main">
                      {allCoin3 !== null ? (
                        allCoin3?.map((item, index) => {
                          return (
                            <div
                              className="avatar_cat_main row my-3"
                              key={`allCoin3_${index}`}
                            >
                              <img
                                src={url.imageUrl + item?.icon?.default}
                                className="avtara_cat_img"
                              />{" "}
                              <h4 className="avatar_cat_name">{item?.name}</h4>
                              <OwlCarousel
                                // className={`owl-theme ${isRTL ? 'rtl' : ''}`}
                                className={`main-slider owl-carousel owl-theme ${isRTL === 'true' ? 'rtl' : ''}`}
                                autoWidth={false}
                                items={2}
                                loop={false}
                                margin={0}
                                stagePadding={50}
                                autoplay={false}
                                dots={false}
                                nav={true}
                                {...Owloptions}
                              // rtl={true}
                              >
                                {item?.avatars?.length &&
                                  item?.avatars?.map((item1, index1) => {
                                    return (
                                      <div
                                        className="item"
                                        style={{ height: "100%" }}
                                        key={`item_avatars_${index}_${index1}`}
                                      >
                                        <div className="bg-design-avatars"></div>
                                        <div className="bundles_first">
                                          <img
                                            src={
                                              url.imageUrl + item1?.img?.default
                                            }
                                          />
                                          <p className="text-center my-2">
                                            <b>{item1?.name}</b>
                                          </p>
                                        </div>
                                        {!item1.isPurchase ? (
                                          <div
                                            className="bundles_second"
                                            style={{ textAlign: "center" }}
                                          >
                                            {
                                              JSON.parse(localStorage.getItem("premiumUser")) ?
                                                <p
                                                  style={{
                                                    display: "-webkit-inline-box",
                                                    color: "#fff",

                                                  }}

                                                >
                                                  <span>{t('coin_store.unlocked')}</span>

                                                </p>
                                                :
                                                <p
                                                  style={{
                                                    display: "-webkit-inline-box",
                                                    color: "#fff",
                                                  }}
                                                  onClick={() => onClickInfo(item1)}
                                                >
                                                  <span>{t('coin_store.buy')}</span>
                                                  <img
                                                    src={Coin}
                                                    className="coin_img mx-1"
                                                  />
                                                  <span>{item1?.coinAmount}</span>
                                                </p>
                                            }

                                          </div>
                                        ) : (
                                          <div
                                            className="bundles_second"
                                            style={{ textAlign: "center" }}
                                          >
                                            <p
                                              style={{
                                                display: "-webkit-inline-box",
                                                color: "#fff",
                                              }}
                                            >
                                              <span>{t('coin_store.bought')}</span>
                                              {/* <img
                                                  src={Coin}
                                                  className="coin_img mx-1"
                                                />
                                                <span>{item1?.coinAmount}</span> */}
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                              </OwlCarousel>
                            </div>
                          );
                        })
                      ) : (
                        // <Loader />
                        <div
                          className="d-flex justify-content-center"
                          style={{}}
                        >
                          <div
                            style={{
                              padding: "20px",
                              // color: "#F92C2C",
                              fontSize: "18px",
                              fontWeight: "1000",
                            }}
                          >
                            {t('notFound.no_records_found')}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : activeTab === 0 ? (
            <div className="all_sub_screens_div_related_to_ads" style={{ overflowY: "inherit", overflowX: "inherit" }}>
              <div className="list-group bg-primary main-tab coin_reward_tabs" id="list-tab" role="tablist">
                <a className={`list-group-item list-group-item-action ${activeTab === 0 && activeReward === 0 ? "active" : "text-dark"}`}
                  id="list-home-list"
                  data-toggle="rewardlist"
                    onClick={() => { getRewardList(); setActiveReward(0) }}
                >
                  {t('coin_store.rewards')}
                </a>
                <a
                  className={`list-group-item list-group-item-action ${activeTab === 0 && activeReward === 1
                    ? "active"
                    : "text-dark"
                    }`}
                  id="list-profile-list"
                  data-toggle="list"
                  onClick={() => { getMyRewardList(1); setActiveReward(1) }}
                >
                  {t('reward_store.my_rewards')}
                </a>
              </div>
              <div
                className="tab-content"
                style={{
                  display: "grid",
                  // placeItems: "center"
                }}
                id="nav-tabContent"
              >
                {activeTab === 0 && activeReward === 0 && (
                  <div className={`active mx-4`}>
                    <div className="allReward1_main" style={{ position: 'relative' }}>
                      {allReward1 !== null ? (
                        allReward1?.length > 0 ? (
                          allReward1?.map((item, index) => {
                            return (
                              <div
                                className="reward_cat_main row my-3"
                                key={`allReward1_${index}`}
                              >
                                <img
                                  src={url.imageUrl + item?.img?.default}
                                  className="avtara_cat_img"
                                  style={{
                                    position: "relative",
                                    top: "-5px",
                                  }}
                                />{" "}
                                <h4 className="reward_cat_name fontSize16">
                                  <span>{item?.name}</span>
                                </h4>
                                <OwlCarousel
                                  className="main-slider owl-carousel owl-theme"
                                  autoWidth={true}
                                  items={2}
                                  loop={false}
                                  margin={15}
                                  stagePadding={50}
                                  autoplay={false}
                                  dots={false}
                                  nav={true}
                                  {...Owloptions}
                                // rtl="true"
                                >
                                  {item?.products?.length &&
                                    item?.products?.map((item1, index1) => {
                                      return (
                                        <div
                                          className="item"
                                          style={{
                                            width: "170px",
                                            height: "170px",
                                            paddingBottom: "5px",
                                            border: "1px solid #000",
                                            borderRadius: "25px",
                                          }}
                                          key={`item_products_${index}_${index1}`}
                                        >
                                          <div className="reward1_first">
                                            <img
                                              style={{
                                                width: "100%",
                                                height: "48%",
                                                objectFit: "cover",
                                              }}
                                              src={
                                                url.imageUrl +
                                                item1?.img?.default
                                              }
                                            />
                                            <div className="w-100 d-flex">
                                              <p
                                                className=""
                                                style={{
                                                  marginTop: 2,
                                                  marginLeft: 5,
                                                }}
                                              >
                                                {item1?.name?.length > 18 ? (
                                                  <marquee
                                                    style={{
                                                      marginBottom: -5,
                                                    }}
                                                  >
                                                    <b>{item1?.name}</b>
                                                  </marquee>
                                                ) : (
                                                  <b>{item1?.name}</b>
                                                )}
                                              </p>
                                            </div>
                                            <div
                                              className="w-100 d-flex"
                                              style={{ marginTop: 0 }}
                                            >
                                              <p
                                                className="col-6"
                                                style={{ marginLeft: 0 }}
                                              >
                                                {item1.isVoucherAmountShow ? (
                                                  <b>
                                                    {
                                                      item1.country.currency
                                                        .symbol
                                                    }{" "}
                                                    {item1.rewardVoucherAmount}
                                                  </b>
                                                ) : (
                                                  ""
                                                )}
                                              </p>
                                              <p
                                                className="col-6 "
                                                style={{
                                                  marginRight: 5,
                                                  marginLeft: -10,
                                                }}
                                              >
                                                <b className="d-flex align-items-center">
                                                  <img
                                                    src={
                                                      url.imageUrl +
                                                      item1.currency.img.default
                                                    }
                                                    style={{
                                                      height: 10,
                                                      width: 15,
                                                      marginRight: 5,
                                                    }}
                                                  />
                                                  {"  "}
                                                  {item1.coinAmount}
                                                </b>
                                              </p>
                                            </div>
                                            <div className="p-0">
                                              <p
                                                className="redeem_click text-white"
                                                onClick={() =>
                                                  onClickRedeem(item1)
                                                }
                                              >
                                                {t('reward_store.redeem')}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </OwlCarousel>
                              </div>
                            );
                          })
                        ) : (
                          <div
                            className="d-flex justify-content-center"
                            style={
                              {
                                // position: "fixed",
                                // top: "50%",
                                // left: "26%",
                                // transform: "translate(-50%, -50%)",
                              }
                            }
                          >
                            <div
                              style={{
                                padding: "20px",
                                // color: "#F92C2C",
                                fontSize: "18px",
                                fontWeight: "1000",
                              }}
                            >
                              {t('notFound.no_records_found')}
                            </div>
                          </div>
                        )
                      ) : (
                        // <Loader />
                        <div
                          className="d-flex justify-content-center"
                          style={{}}
                        >
                          <div
                            style={{
                              padding: "20px",
                              // color: "#F92C2C",
                              fontSize: "18px",
                              fontWeight: "1000",
                            }}
                          >
                            {t('notFound.no_records_found')}
                          </div>
                        </div>
                      )}

                      {showRedeem === true && (
                        <div
                          className="custom-popup home-featured show redeem_popup bg-light"
                          style={currentLang?.toLowerCase() == "ar" ? {
                            right: "90.5rem"
                          } : {

                          }}
                          id="contact_popup"
                        >
                          <div
                            className="popup-head bg-light"
                            style={{ margin: "-20px -20px 0px" }}
                          >
                            <h3>{redeemData?.name}</h3>
                            <button
                              className="close-popup"
                              onClick={() => setShowRedeem(false)}
                            >
                              <img src={Close} alt="" />
                            </button>
                          </div>
                          <div className="popup-body text-center mb-3">
                            <div className="all_sub_screens_div_related_to_ads">
                              <p className="mb-2">
                                <img
                                  src={url.imageUrl + redeemData?.img}
                                  className="coin_img"
                                />{" "}
                                <b>{redeemData?.coinAmount}</b>
                              </p>
                              <p className="mb-2">{redeemData?.description}</p>
                              <button
                                className="btn btn-primary"
                                style={{
                                  width: "fit-content",
                                  textTransform: "capitalize",
                                  margin: "0 auto 50px auto",
                                  whiteSpace: "nowrap",
                                }}
                                onClick={onClickRedeemItem}
                              >
                                <img
                                  src={url.imageUrl + redeemData?.img}
                                  className="coin_img"
                                />{" "}
                                {redeemData?.coinAmount} {t('reward_store.redeem')}
                              </button>
                            </div>
                            {
                              !JSON.parse(localStorage.getItem("premiumUser")) &&

                              <Advertisement
                                screen={
                                  localStorage.getItem("apk_screens_list") === null
                                    ? { code: "no_results" }
                                    : JSON.parse(localStorage.getItem("apk_screens_list"))
                                      .redeemRewardsPopup
                                }
                                screen_div_class_name=".all_sub_screens_div_related_to_ads"
                                screen_type="sub"
                              />
                            }
                          </div>
                        </div>
                      )}
                    </div>

                  </div>
                )}
                {/* {console.log(allReward2)} */}
                {activeTab === 0 && activeReward === 1 && (
                  <div className={`active mx-4"`} id="rewardlist-2">
                    <div className="allReward2_main">
                      {allReward2 !== null ? (
                        allReward2.length > 0 ? (
                          <>
                            {
                              allReward2?.map((item, index) => {
                                if (item && "reward" in item) {
                                  return (
                                    <div
                                      className="my_rewads_main bg-dark text-light"
                                      key={`allReward2_${index}`}
                                    >
                                      {tagShowHowToRedeemPopup[index] &&
                                        createHowToRedeemPopup(
                                          item?.rewardVoucher,
                                          index
                                        )}
                                      <div
                                        className="row m-0"
                                        style={{
                                          alignItems: "center",
                                          paddingBottom: "5px",
                                        }}
                                      >
                                        <div className="col-2 p-0 d-flex justify-content-center">
                                          <img
                                            src={
                                              url.imageUrl +
                                              item?.reward?.img?.default
                                            }
                                            className="coupe_img"
                                          />
                                        </div>
                                        <div className={currentLang?.toLowerCase() == "ar" ? "col-10 pr-2 pr-0 mb-auto" : "col-10 pl-2 pr-0 mb-auto"}>
                                          <div className="row m-0 align-items-center justify-content-between">
                                            <h4
                                              className="mb-0 fontSize16"
                                              style={currentLang?.toLowerCase() == "ar" ? {
                                                fontWeight: "600",
                                                width: "50%",
                                                textAlign: "right"
                                              } : {
                                                fontWeight: "600",
                                                width: "50%",
                                              }}
                                            >
                                              {item?.reward?.name?.length < 12 ? (
                                                item?.reward?.name
                                              ) : (
                                                <marquee>
                                                  {item?.reward?.name}
                                                </marquee>
                                              )}
                                            </h4>
                                            <h5
                                              className="mb-0 text-center my_rewards_pending_failed_success_status fontSize16"
                                              style={{
                                                fontWeight: "600",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {item?.deliveryStatus?.toLowerCase() ===
                                                "delivered" && (
                                                  <img
                                                    src={SuccessPayment}
                                                    className={currentLang?.toLowerCase() == "ar" ? "coin_img_myreward ml-1" : "coin_img_myreward mr-1"}
                                                  />
                                                )}
                                              {item?.deliveryStatus?.toLowerCase() ===
                                                "pending" && (
                                                  <img
                                                    src={PendingPayment}
                                                    className={currentLang?.toLowerCase() == "ar" ? "coin_img_myreward ml-1" : "coin_img_myreward mr-1"}
                                                  />
                                                )}
                                              {item?.deliveryStatus?.toLowerCase() ===
                                                "PENDING" && (
                                                  <img
                                                    src={PendingPayment}
                                                    className={currentLang?.toLowerCase() == "ar" ? "coin_img_myreward ml-1" : "coin_img_myreward mr-1"}
                                                  />
                                                )}
                                              {item?.deliveryStatus?.toLowerCase() ==
                                                "failed" && (
                                                  <img
                                                    src={FailPayment}
                                                    className={currentLang?.toLowerCase() == "ar" ? "coin_img_myreward ml-1" : "coin_img_myreward mr-1"}
                                                  />
                                                )}{" "}
                                              {item?.deliveryStatus?.toLowerCase() ===
                                                "delivered" && t('reward_store.success')}
                                              {item?.deliveryStatus?.toLowerCase() ===
                                                "pending" && t('reward_store.pending')}
                                              {item?.deliveryStatus?.toLowerCase() ===
                                                "PENDING" && t('reward_store.pending')}
                                              {item?.deliveryStatus?.toLowerCase() ===
                                                "failed" && t('reward_store.failed')}
                                            </h5>
                                          </div>
                                          {currentLang?.toLowerCase() == "ar" ?
                                            <div className="row m-0 align-items-center">
                                              <div
                                                className=""
                                                style={{
                                                  borderLeft: "1px solid #fff",
                                                }}
                                              >
                                                <h5
                                                  className="mb-0 text-center d-flex align-items-center fontSize16"
                                                  style={{
                                                    fontWeight: "600",
                                                    paddingLeft: "20px",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      url.imageUrl +
                                                      item?.currency.img.default
                                                    }
                                                    className="coin_img_myreward mr-2"
                                                  />{" "}
                                                  {item?.reward?.coinAmount}
                                                </h5>
                                              </div>
                                              <small style={{ paddingRight: "20px" }}>
                                                {getConvertionDateTime(
                                                  item?.createdAt
                                                )}
                                              </small>
                                            </div>
                                            :
                                            <div className="row m-0 align-items-center">
                                              <div
                                                className=""
                                                style={{
                                                  borderRight: "1px solid #fff",
                                                }}
                                              >
                                                <h5
                                                  className="mb-0 text-center d-flex align-items-center fontSize16"
                                                  style={{
                                                    fontWeight: "600",
                                                    paddingRight: "20px",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      url.imageUrl +
                                                      item?.currency.img.default
                                                    }
                                                    className="coin_img_myreward mr-2"
                                                  />{" "}
                                                  {item?.reward?.coinAmount}
                                                </h5>
                                              </div>
                                              <small style={{ paddingLeft: "20px" }}>
                                                {getConvertionDateTime(
                                                  item?.createdAt
                                                )}
                                              </small>
                                            </div>
                                          }
                                        </div>
                                      </div>
                                      <div
                                        className="row m-0 bg-primary text-dark reward_coin_second align-items-center"
                                        style={{
                                          borderRadius: "10px",
                                          fontWeight: "700",
                                        }}
                                      >
                                        <div className="col-10 p-0">
                                          <div className="d-flex align-items-center p-1">
                                            {item?.redeemVoucherCode?.length < 36 ? (
                                              item.redeemVoucherCode
                                            ) : (
                                              <marquee>
                                                {item.redeemVoucherCode}
                                              </marquee>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          className="col-2 p-0 bg-secondary text-white"
                                          style={{
                                            borderRadius: "0 10px 10px 0",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              item?.redeemVoucherCode
                                            );
                                            toast.info(t('info_Copied'), {
                                              position: "top-left",
                                              autoClose: 5000,
                                              hideProgressBar: false,
                                              closeOnClick: true,
                                              pauseOnHover: true,
                                              draggable: true,
                                              progress: undefined,
                                            });
                                          }}
                                        >
                                          {item?.deliveryStatus === "FAILED" ? (
                                            <></>
                                          ) : (
                                            <div className="d-flex align-items-center p-1 justify-content-center">
                                              {t('coin_store.copy')}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      {item?.deliveryStatus === "FAILED" ? (
                                        <></>
                                      ) : (
                                        <div
                                          className="row w-100 align-items-center justify-content-center"
                                          style={{
                                            margin: "0 auto",
                                            paddingTop: "5px",
                                            fontSize: "18px",
                                            fontWeight: "600",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            let temp = [...tagShowHowToRedeemPopup];
                                            temp[index] = true;
                                            setTagShowHowToRedeemPopup(temp);
                                          }}
                                        >
                                          {t('coin_store.how_to_redeem')}
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                              })
                            }
                            {allReward2.length !== myRewardTotalRecord &&
                              <div className="load_more text-center">
                                <button
                                  className="btn btn-primary"
                                  onClick={() => getLoadMore()}
                                >
                                  {t('contest.load_more')}
                                </button>
                              </div>
                            }
                          </>
                        ) : (
                          <div
                            className="d-flex justify-content-center"
                            style={{}}
                          >
                            <div
                              style={{
                                padding: "20px",
                                // color: "#F92C2C",
                                fontSize: "18px",
                                fontWeight: "1000",
                              }}
                            >
                              {t('notFound.no_records_found')}
                            </div>
                          </div>
                        )
                      ) : (
                        // <Loader />
                        <div
                          className="d-flex justify-content-center"
                          style={{}}
                        >
                          <div
                            style={{
                              padding: "20px",
                              // color: "#F92C2C",
                              fontSize: "18px",
                              fontWeight: "1000",
                            }}
                          >
                            {t('notFound.no_records_found')}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <EarnCoins />
          )
 }
          {
            !JSON.parse(localStorage.getItem("premiumUser")) &&

            <Advertisement
              screen={
                localStorage.getItem("apk_screens_list") === null
                  ? { code: "no_results" }
                  : JSON.parse(localStorage.getItem("apk_screens_list")).coinStore
              }
              screen_div_class_name=".all_main_screens_div_related_to_ads"
              screen_type="main"
            />
          }



          {showInfo === true && (
            <div
              className="custom-popup home-featured show redeem_popup bg-primary"
              id="contact_popup1"
            >
              <div className="popup-head bg-primary">
                <button
                  className="close-popup"
                  onClick={() => setShowInfo(false)}
                >
                  <img src={Close} alt="" />
                </button>
              </div>
              <div className="popup-body text-center mb-3">
                <div className="bundles_first">
                  <img
                    src={url.imageUrl + avatarData?.img?.default}
                    style={{ height: "250px" }}
                  />
                  <p className="text-center my-2 avatar-title-box">
                    <b>{avatarData?.name}</b>
                  </p>
                </div>
                <div
                  className="bundles_second_pop"
                  style={{ textAlign: "center" }}
                >
                  <p
                    style={{
                      display: "-webkit-inline-box",
                      color: "#fff",
                    }}
                    onClick={() => OnClickBuyAvatar(avatarData._id)}
                  >
                    <img src={Coin} className="coin_img mx-1" />

                    <span>{avatarData?.coinAmount}</span>
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* {console.log(bundlesData, "hfh")} */}
          {/* {showBundlesInfo === true && (
            <div
              className="custom-popup home-featured show redeem_popup bg-primary"
              id="contact_popup1"
            >
              <div className="popup-head bg-primary">
                <button
                  className="close-popup"
                  onClick={() => setShowBundlesInfo(false)}
                >
                  <img src={Close} alt="" />
                </button>
              </div>
              <div className="popup-body text-center mb-3">
                <div className="bundles_first">
                  <img
                    src={url.imageUrl + bundlesData?.avatar?.img?.default}
                    style={{ height: "250px" }}
                    alt="avatar"
                  />
                  <p className="text-center my-2 bundle-title-box">
                    <p>
                      <b className="bundle-title-p">{bundlesData?.name}</b>{" "}
                    </p>
                    <b>
                      <img
                        src={Coin}
                        className="coin_img mx-1"
                        style={{
                          width: "20px",
                        }}
                        alt="coin"
                      />{" "}
                      {bundlesData?.description} {t('coin_store.coin')} + {bundlesData?.name}
                    </b>
                  </p>
                </div>
                <div
                  className="bundles_second_pop"
                  style={{ textAlign: "center" }}
                  onClick={() => onClickBuyItem2(bundlesData)}
                >
                  {t('coin_store.buy')} {bundlesData?.currency?.symbol} {bundlesData?.amount}
                </div>
              </div>
            </div>
          )} */}
          {
            showPayuForm && <PayUForm showPayuForm = {showPayuForm} setShowPayuForm={setShowPayuForm} payload={payUData} isBundle={showBundlesInfo} bundlesData = {bundlesData} setShowBundlesInfo= {setShowBundlesInfo} />
          }
        </div>
       

      </div>
      {isQrCode && QrCodePopUp()}
    </Fragment>
  );
}

export default CoinStore;
