import React, { useEffect, useState } from "react";
// import "./assets/css/bootstrap.min.css";
import "./assets/css/all.min.css";
import "./assets/css/font.css";
// import "./assets/css/mCustomScrollbar.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import { useLocation, Routes, Route } from "react-router-dom";
// import OneSignal from 'react-onesignal';
// import Adjust from '@adjustcom/adjust-web-sdk';

import Sidebar from "./component/sidebar";
import Footer from "./component/footer";
import HomePage from "./screens/index";
import { Col } from "react-bootstrap";
import SignIn from "./screens/signin";
import SignInCampaign from "./screens/signin-campaign";
import Register from "./screens/register";
import RegisterEmail from "./screens/register-email";
import SigninEmail from "./screens/signin-email";
import RegisterCampaign from "./screens/register-campaign";
import SignUp from "./screens/signup";
import OTP from "./screens/otp";
import OTPemail from "./screens/otpemail";
import OTPregisteredmail from "./screens/otpregisteredmail";
import More from "./screens/more";
import AddBalance from "./screens/add-balance";
import Bank from "./screens/bank";
import ChangePassword from "./screens/change-password";
import ContestDetails from "./screens/contest-details";
import ContestDetailsOld from "./screens/contest-detail-old";
import ContestList from "./screens/contest-list";
import CustomerCare from "./screens/customer-care";
import DailyLoginRewards from "./screens/daily-login-rewards";
import DobNState from "./screens/dob-n-state";
import CardDetails from "./screens/enter-card-details";
// import Game from "./screens/game";
import LeaderBoard from "./screens/leader-board";
import Legality from "./screens/legality";
import MonthlyChnampionship from "./screens/monthly-championship";
import PAN from "./screens/pan";
import RecentTransaction from "./screens/recent-transactions";
import TicketNo from "./screens/ticket-no";
import Tournaments from "./screens/tournaments";
import { routehelp } from "./config/routehelp";
// import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
import SingleGame from "./screens/single-game";
import NotFound from "./screens/not-found";
import GoHome from "./screens/go-home";
import TournamentDetail from "./screens/tournament-details";
import MyContest from "./screens/my-contest";

import TournamentDetails from "./screens/tournament-details";
import Account from "./screens/account";
import CoinStore from "./screens/coinStore";
import PaymentOptions from "./screens/payment-options";
import PaymentOptionsWeb from "./screens/payment-options-web";
import PaymentStatus from "./screens/payment-status";
import Profile from "./screens/profile";
import { Profile1 } from "./screens/Profile/Profile1";
import TermsCondition from "./screens/termsCondition";
import PrivacyPolicy from "./screens/privacyPolicy";
import GamerjiPoints from "./screens/gamerji-points";
import SearchUser from "./screens/search-user";
import ProfileInsights from "./screens/profile-insights";
import ProfileEdit from "./screens/profile-edit";
import CollegiateDetails from "./screens/collegiate-details";
import Friends from "./screens/friends";
import UserProfile from "./screens/user-profile";
import WorldOfEsport from "./screens/world-of-esports";
import WorldOfEsportAllBlogs from "./screens/WOEAllEsportsNews";
import WorldOfEsportAllVideos from "./screens/WOEAllFeaturedVideos";
import WorldOfEsportAllProfile from "./screens/WOEAllTopProfiles";
import WorldOfEsportIndividualBlog from "./screens/WOEIndividualEsportsNews";
import ReferAFriend from "./screens/ReferAFriend";
// import HowToPlay from "./screens/HowToPlay";
import FreeGames from "./screens/Free Games/FreeGames";
import FreeGameType from "./screens/Free Games/FreeGameType";
import FreeGameSingle from "./screens/Free Games/FreeGameSingle";
import MyRecentTransactions from "./screens/MyRecentTransactions";
import PaymentGateway from "./screens/PaymentGateway";
import PaymentGatewayNew from "./screens/PaymentGatewayNew";
import PGPaymentStatus from "./screens/pg-payment-status";
import PaymentGatewayWeb from "./screens/PaymentGatewayWeb";
import PGPaymentStatusWeb from "./screens/pg-payment-status-web";
import Withdrawal from "./screens/Withdrawal";
import WithdrawalWeb from "./screens/WithdrawalWeb";
import VerifyMobileEmail from "./screens/VerifyMobileEmail";
import MyVideos from "./screens/More/MyVideos";
import { initializeApp } from "firebase/app";
import { PrivateScrims } from "./screens/Scrims/PrivateScrims";
import { Howtocreatescrimbs } from "./screens/Scrims/Howtocreatescrims";
// import { CreateScrim } from "./screens/Scrims/CreateScrim";
import { ScrimSummary } from "./screens/Scrims/ScrimSummary";
import { ScrimDetailpage } from "./screens/Scrims/ScrimDetailpage";
import ScrimMain from "./component/ScrimComponent/ScrimMain";
import PhonePeResponse from "./screens/PhonePeResponse";
import PhonepeStatus from "./screens/PhonePeStatus";
import ScrimCreate from "./component/ScrimComponent/ScrimCreate";
import XsollaPayment from "./screens/XsollaPayment";
import XsollaStatus from "./screens/XsollaStatus";
// import { getSettings, isUserPremium } from "./apis";
import AdPage from "./screens/Scrims/AdPage";
import DirectLinkPage from "./screens/Free Games/DirectLinkPage";
import SubscriptionUI from "./component/Subscription/SubscriptionUI";
import XsollaSubscriptionStatus from "./screens/XsollaSubscriptionStatus";
import { useTranslation } from "react-i18next";

import { func } from "./logDetails";
import AirPayStatus from "./screens/AirPayStatus";
import Cookies from 'js-cookie'
import PayUForm from "./component/PayU/PayUForm";
import PayUSuccess from "./component/PayU/PayUSuccess";


// var mCustomScrollbar = require("malihu-custom-scrollbar-plugin");
export const authUser = (props) => {
  return (
    <Route
      exact
      path={props.path}
      element={
        localStorage.getItem("gamerjiToken") === null ? (
          <SignIn />
        ) : (
          props.element
        )
      }
    />
  );
};

function App() {
  const [nav, setNav] = useState(false);
  const { t } = useTranslation()

  const location = useLocation();
  // const [initialized, setInitialized] = useState(false);
  const navMenuClick = () => {
    setNav(!nav);
  };
  // OneSignal.init({ appId: '32c72730-7db3-43b9-af77-1a0874c9cec1' }).then(() => {
  //   setInitialized(true);
  //   OneSignal.showSlidedownPrompt().then(() => {
  //     // do other stuff
  //   });
  // })
  //OneSignal.init({ appId: '32c72730-7db3-43b9-af77-1a0874c9cec1' });
  useEffect(() => {
    // scrollBarLoaded();
    // console.log(location.pathname, "---------");
  }, [location]);

  var firebaseConfig = {
    apiKey: "AIzaSyBw-xOChQWFv8oS8HF56HffCyc9FaW0I9w",
    authDomain: "https://web.gamerji.com",
    projectId: "fantasyji-esports",
    storageBucket: "fantasyji-esports.appspot.com",
    messagingSenderId: "454874517092",
    appId: "1:454874517092:ios:18d91115f592bfa854d971",
  };

  const fetchData = async () => {
    // let data = {
    //   company: "634082e312598b8e50ef0d78",
    // };
    // let res = await getSettings(data);
    // if (res?.success) {
    //   localStorage.setItem("isDefault", res?.country?.isXsollaPayment);
    //   localStorage.setItem("companyId", res?.data?._id);
    // } else localStorage.setItem("isDefault", false);
    localStorage.setItem("isDefault", true);
  };
  const currentLang = Cookies.get('i18next') || 'EN'

  useEffect(() => {
    // console.log("called")
    //



    const linkTag = document.getElementById('bootstrap_css');
    document.documentElement.dir =
      currentLang.toLowerCase() === "ar" ? "rtl" : "ltr";
    if (currentLang.toLowerCase() === "ar") {
      document.documentElement.setAttribute('lang', 'ar');
      linkTag?.setAttribute(
        'href',
        `${process.env.REACT_APP_CLIENT_BASE_URL}asserts/css/bootstrap.rtl.min.css`
      );
    }
    document.title = t('title')
    fetchData();
  }, [t]);

  initializeApp(firebaseConfig);

  // Adjust.initSdk({
  //   appToken: '91tmflkg8uf4',
  //   environment: 'sandbox'
  // });

  // const scrollBarLoaded = () => {
  //   console.log("here::12");
  //   return new Promise((resolve, reject) => {
  //     const scrollStyle = document.createElement("link");
  //     scrollStyle.type = "text/css";
  //     scrollStyle.rel = "stylesheet";
  //     scrollStyle.href =
  //       "https://cdnjs.cloudflare.com/ajax/libs/malihu-custom-scrollbar-plugin/3.1.5/jquery.mCustomScrollbar.min.css";
  //     document.getElementsByTagName("head")[0].appendChild(scrollStyle);

  //     const scrollScript = document.createElement("script");
  //     // scrollScript.onload = resolve
  //     scrollScript.onerror = reject;
  //     scrollScript.id = "scroll_script";
  //     scrollScript.async = true;
  //     scrollScript.src =
  //       "https://cdnjs.cloudflare.com/ajax/libs/malihu-custom-scrollbar-plugin/3.1.5/jquery.mCustomScrollbar.concat.min.js";
  //     document.body.appendChild(scrollScript);
  //   });
  // };
  // function goHomePage(){
  //   window.location.href = '/';
  // }
  // const location = useLocation();

  // useEffect(() => {
  //   // console.log("here::12", location.pathname, localStorage.getItem('gamerjiToken'));

  // }, [location]);

  return (
    <div
      className={`wrapper ${(window?.location?.pathname === "/" ||
        window?.location?.pathname === "/more" ||
        window?.location?.pathname === "/ticket-no" ||
        window?.location?.pathname === "/tournaments" ||
        window?.location?.pathname === "/profile" ||
        window?.location?.pathname === "/world-of-esports" ||
        window?.location?.pathname === "/coin-store") &&
        localStorage.getItem("gamerjiToken") !== null &&
        "with-footer"
        } menumain ${nav === true && "navigation"}`}
      id="mobile"
    >
      <div className="row no-gutters side-main">
        <Col md={5} lg={3}>
          <Routes>
            {/* <Route
                exact
                path={routehelp.default}
                element={<HomePage navMenuClick={navMenuClick} />}
              /> */}
            {/* {!isLoggedinUser && <Redirect to="/" />} */}
            {/* <Route exact path={routehelp.default} element={localStorage.getItem('gamerjiToken') === null ? <RegisterEmail /> : <HomePage navMenuClick={navMenuClick}
             />} /> */}
            <Route
              exact
              path={routehelp.default}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <SignIn />
                ) : (
                  <HomePage navMenuClick={navMenuClick} />
                )
              }
            />

            <Route
              exact
              path={routehelp.signin}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <SignIn />
                ) : (
                  <GoHome />
                )
              }
            />
            <Route
              exact
              path={routehelp.signinCampaign}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <SignInCampaign />
                ) : (
                  <GoHome />
                )
              }
            />
            <Route
              exact
              path={routehelp.register}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <Register />
                ) : (
                  <GoHome />
                )
              }
            />
            <Route
              exact
              path={routehelp.registerCampaign}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <RegisterCampaign />
                ) : (
                  <GoHome />
                )
              }
            />
            <Route
              exact
              path={routehelp.registerEmail}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <RegisterEmail />
                ) : (
                  <GoHome />
                )
              }
            />

            <Route
              exact
              path={routehelp.signinEmail}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <SigninEmail />
                ) : (
                  <GoHome />
                )
              }
            />
            <Route exact path={routehelp.signup} element={<SignUp />} />
            <Route
              exact
              path={routehelp.otp}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <OTP />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              exact
              path={routehelp.otpemail}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <OTPemail />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              exact
              path={routehelp.otpemailregister}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <OTPregisteredmail />
                ) : (
                  <NotFound />
                )
              }
            />
            {/* <Route exact path={routehelp.otpemailregister} element={<OTPregisteredmail/>} /> */}
            <Route
              exact
              path={routehelp.more}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <SignIn />
                ) : (
                  <More />
                )
              }
            />

            {/* <Route exact path={routehelp.more} element={<More />} /> */}
            <Route
              exact
              path={routehelp.contestdetail}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <SignIn />
                ) : (
                  <TournamentDetail />
                )
              }
            />
            <Route
              exact
              path={routehelp.singlegame}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <SignIn />
                ) : (
                  <SingleGame />
                )
              }
            />

            <Route exact path={routehelp.addbalance} element={<AddBalance />} />
            <Route
              exact
              path={routehelp.paymentOptions}
              element={<PaymentOptions />}
            />
            <Route
              exact
              path={routehelp.paymentOptionsWeb}
              element={<PaymentOptionsWeb />}
            />
            <Route
              exact
              path={routehelp.paymentStatus}
              element={<PaymentStatus />}
            />
            <Route
              exact
              path={routehelp.pgPaymentStatus}
              element={<PGPaymentStatus />}
            />
            <Route
              exact
              path={routehelp.profileInsights}
              element={<ProfileInsights />}
            />
            <Route
              exact
              path={routehelp.profileEdit}
              element={<ProfileEdit />}
            />
            <Route
              exact
              path={routehelp.userProfile}
              element={<UserProfile />}
            />
            <Route exact path={routehelp.friends} element={<Friends />} />
            <Route
              exact
              path={routehelp.collegiateDetails}
              element={<CollegiateDetails />}
            />
            <Route exact path={routehelp.bank} element={<Bank />} />
            <Route
              exact
              path={routehelp.changepassword}
              element={<ChangePassword />}
            />
            <Route exact path={routehelp.mycontest} element={<MyContest />} />
            <Route
              exact
              path={routehelp.privateScrims}
              element={<PrivateScrims />}
            />
            <Route
              exact
              path={routehelp.howtocreateScrimbs}
              element={<Howtocreatescrimbs />}
            />
            <Route
              exact
              path={routehelp.createScrim}
              element={<ScrimCreate />}
            />
            <Route exact path={routehelp.editScrim} element={<ScrimCreate />} />
            <Route
              excet
              path={routehelp.scrimSummary}
              element={<ScrimSummary />}
            />

            <Route
              excet
              path={routehelp.scrimDetail}
              element={<ScrimDetailpage />}
            />

            <Route
              exact
              path={routehelp.scrimLeaderBoard}
              element={<ScrimMain />}
            />
            <Route
              exact
              path={routehelp.contestdetails}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <SignIn />
                ) : (
                  <ContestDetails />
                )
              }
            />
            <Route
              exact
              path={routehelp.contestdetailsold}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <SignIn />
                ) : (
                  <ContestDetailsOld />
                )
              }
            />
            <Route
              exact
              path={routehelp.tournamentdetail}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <PaymentGateway />
                ) : (
                  <TournamentDetails />
                )
              }
            />
            <Route exact path={routehelp.coinStore} element={  localStorage.getItem("gamerjiToken") === null ? 
                  <SignIn /> : < CoinStore />} />
            <Route
              exact
              path={routehelp.myRecentTransactions}
              element={<MyRecentTransactions />}
            />
            <Route
              exact
              path={routehelp.paymentGateway}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <PaymentGateway />
                ) : (
                  <PaymentGateway />
                )
              }
            />
            <Route
              exact
              path={routehelp.paymentGatewayNew}
              element={<PaymentGatewayNew />}
            />
            <Route
              exact
              path={routehelp.paymentGatewayWeb}
              element={<PaymentGatewayWeb />}
            />
            <Route
              exact
              path={routehelp.pgPaymentStatusWeb}
              element={<PGPaymentStatusWeb />}
            />
            <Route exact path={routehelp.withdrawal} element={<Withdrawal />} />
            <Route
              exact
              path={routehelp.withdrawalWeb}
              element={<WithdrawalWeb />}
            />
            <Route
              exact
              path={routehelp.verifyMobileEmail}
              element={<VerifyMobileEmail />}
            />
            <Route exact path={routehelp.myVideos} element={<MyVideos />} />
            <Route exact path={routehelp.profile} element={<Profile />} />
            <Route exact path={routehelp.profile1} element={<Profile1 />} />
            <Route
              exact
              path={routehelp.contestlist}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <SignIn />
                ) : (
                  <ContestList />
                )
              }
            />
            <Route
              exact
              path={routehelp.customercare}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <PaymentGateway />
                ) : (
                  <CustomerCare />
                )
              }
            />
            <Route
              exact
              path={routehelp.dailyloginrewards}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <PaymentGateway />
                ) : (
                  <DailyLoginRewards />
                )
              }
            />
            <Route
              exact
              path={routehelp.referAFriend}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <PaymentGateway />
                ) : (
                  <ReferAFriend />
                )
              }
            />
            {/* <Route
                exact
                path={routehelp.howToPlay}
                element={<HowToPlay />}
              /> */}
            <Route
              exact
              path={routehelp.gamerjipoints}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <PaymentGateway />
                ) : (
                  <GamerjiPoints />
                )
              }
            />
            <Route exact path={routehelp.dobnstate} element={<DobNState />} />
            <Route exact path={routehelp.account} element={<Account />} />
            <Route
              exact
              path={routehelp.entercarddetails}
              element={<CardDetails />}
            />
            {/* <Route exact path={routehelp.game} element={<Game />} /> */}

            <Route
              exact
              path={routehelp.gamesCategories}
              element={<FreeGames />}
            />
            <Route exact path={routehelp.gameType} element={<FreeGameType />} />
            <Route
              exact
              path={routehelp.directLinkpage}
              element={<DirectLinkPage />}
            />

            <Route exact path={routehelp.game} element={<FreeGameSingle />} />

            <Route
              exact
              path={routehelp.leaderboard}
              element={<LeaderBoard />}
            />
            <Route exact path={routehelp.searchuser} element={<SearchUser />} />
            <Route exact path={routehelp.legality} element={<Legality />} />
            <Route
              exact
              path={routehelp.monthlychampionship}
              element={<MonthlyChnampionship />}
            />
            <Route exact path={routehelp.pan} element={<PAN />} />
            <Route
              exact
              path={routehelp.recenttransactions}
              element={<RecentTransaction />}
            />
            <Route exact path={routehelp.ticketno} element={<TicketNo />} />
            <Route exact path={routehelp.terms} element={<TermsCondition />} />
            <Route exact path={routehelp.privacy} element={<PrivacyPolicy />} />
            <Route
              exact
              path={routehelp.tournaments}
              element={localStorage.getItem("gamerjiToken") === null ? (
                <SignIn />
              ) : <Tournaments />}
            />
            <Route
              exact
              path={routehelp.worldOfEsports}
              element={<WorldOfEsport />}
            />
            <Route
              exact
              path={routehelp.woeallbloags}
              element={<WorldOfEsportAllBlogs />}
            />
            <Route
              exact
              path={routehelp.woeallprofile}
              element={<WorldOfEsportAllProfile />}
            />
            <Route
              exact
              path={routehelp.woeallvideos}
              element={<WorldOfEsportAllVideos />}
            />
            <Route
              exact
              path={routehelp.woeallindivisualblog}
              element={<WorldOfEsportIndividualBlog />}
            />
            <Route
              exact
              path={routehelp.phoneStatus}
              element={<PhonePeResponse />}
            />

            <Route
              exact
              path={routehelp.phonepeStatus}
              element={<PhonepeStatus />}
            />

            <Route
              exact
              path={routehelp.scrimCreate}
              element={<ScrimCreate />}
            />
            <Route
              exact
              path={routehelp.xsollPayment}
              element={<XsollaPayment />}
            />
            <Route
              exact
              path={routehelp.xsollaStatus}
              element={<XsollaStatus />}
            />
            <Route
              exact
              path={routehelp.airpayStatus}
              element={<AirPayStatus />}
            />
            <Route exact path={routehelp.adPage} element={<AdPage />} />
            <Route exact path={routehelp.payUStatus} element={<PayUSuccess/>} />


            <Route
              exact
              path={routehelp.premiumSubscription}
              element={
                localStorage.getItem("gamerjiToken") === null ? (
                  <RegisterCampaign />
                ) : (
                  <SubscriptionUI />
                )
              }
            />

            <Route
              exact
              path={routehelp.xsollaSubscriptionStatus}
              element={<XsollaSubscriptionStatus />}
            />
          </Routes>

          {(location.pathname === "/" ||
            location.pathname === "/profile" ||
            location.pathname === "/profile1" ||
            location.pathname === "/more" ||
            location.pathname === "/world-of-esports" ||
            location.pathname === "/ticket-no" ||
            location.pathname === "/tournaments" ||
            location.pathname === "/coin-store") &&
            localStorage.getItem("gamerjiToken") !== null && <Footer />}
        </Col>
        <Col md={7} lg={9} className="main-banner">
          <Sidebar />
        </Col>
      </div>
    </div>
  );
}

export default App;
